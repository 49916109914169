@charset "UTF-8";
/* CSS architecture explanation: https://smacss.com/book/categorizing */
/* We're building bootstrap and FontAwesome from SCSS to take advantage of their mixins and variable overrides */
/* VARIABLES */
/*** VARIABLES ***/
/*** NOTE: These are in front of the Bootstrap build so Bootstrap variables can/should be overriden here ***/
/* COLORS */
/* FONTS */
/* LINKS */
/* COMPONENT DEFAULTS */
/* COMPARISON PAGE */
/* Search */
/* Detail */
/* OVERRIDE BOOTSTRAP VARIABLES */
/* IMAGES DIRECTORY */
/* Colors */
/* GENERAL */
/* LINKS */
/* BUTTONS */
/* FONTS */
/* FORMS */
/* MODULES */
main .umbraco-forms-form input.text, main .umbraco-forms-form textarea {
  max-width: 100% !important;
  width: 100%;
}

main .umbraco-forms-form {
  margin-bottom: 50px;
  font-size: 14px;
  /*span.contourError, span.field-validation-error, label.umbraco-forms-label {
            color: red !important;
        }*/
}

main .umbraco-forms-form fieldset {
  padding-left: 0;
  padding-right: 0;
}

main .umbraco-forms-form .row-fluid {
  margin-left: -15px;
  margin-right: -15px;
}

main .umbraco-forms-form .umbraco-forms-indicator {
  color: red;
}

main .umbraco-forms-form label.umbraco-forms-label {
  font-weight: normal;
}

main .umbraco-forms-form input.text, main .umbraco-forms-form textarea {
  padding: 10px 10px;
  border-radius: 5px;
  border-width: 2px;
}

main .umbraco-forms-form .umbraco-forms-navigation {
  text-align: right;
}

main .umbraco-forms-form .umbraco-forms-navigation input[type="submit"] {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  padding: 10px 55px;
  background-color: #e6762c;
  color: #fff;
  text-shadow: 0px 0px 5px #9a4812;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 15px;
}

main .umbraco-forms-form .umbraco-forms-navigation input[type="submit"] span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

main .umbraco-forms-form .umbraco-forms-navigation input[type="submit"] span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

main .umbraco-forms-form .umbraco-forms-navigation input[type="submit"]:hover {
  background-color: #c75e18;
}

.range-slider-container.number-slider {
  width: 100% !important;
}

.range-slider-container .slider {
  width: 90%;
  margin-bottom: 15px !important;
}

.range-slider-container .slider .slider-track {
  background-color: #b1b1b1;
  background-image: none;
}

.range-slider-container .slider .slider-track .slider-selection {
  background-image: linear-gradient(to bottom, #2389b3 0, #2389b3 100%);
}

.range-slider-container .slider .slider-tick-label-container {
  margin-top: -40px;
}

.range-slider-container .slider .slider-tick-label-container .slider-tick-label {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #1e4566;
  font-weight: normal;
}

.range-slider-container .slider .slider-tick-label-container .slider-tick-label:first-child, .range-slider-container .slider .slider-tick-label-container .slider-tick-label:last-child {
  color: #000;
  font-weight: 600;
}

.range-slider-container .slider .slider-tick-container .slider-tick {
  display: none;
}

.range-slider-container .slider .slider-handle {
  background-color: #16334c;
  background-image: none;
  height: 40px;
  width: 40px;
  top: -10px;
  left: -1%;
}

.range-slider-container .slider .slider-handle:after {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #f2f2f2;
  font-size: 18px;
  padding-right: 0.5em;
  position: absolute;
  top: 10px;
}

.range-slider-container .slider .slider-handle.min-slider-handle:after {
  content: "\f053";
  left: 11px;
}

.range-slider-container .slider .slider-handle.max-slider-handle:after {
  content: "\f054";
  left: 15px;
}

.range-slider-container .slider .slider-handle:focus, .range-slider-container .slider .slider-handle:active {
  outline: none;
}

.range-slider-container .slider .slider-handle.round:focus {
  background-color: #000;
  border-color: #000;
}

.range-slider-container.number-slider {
  width: 100% !important;
  margin-top: 0 !important;
}

.range-slider-container.number-slider label {
  display: block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 14px;
}

.range-slider-container.number-slider label span {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #000;
  font-weight: normal;
  font-weight: bold;
}

.range-slider-container.number-slider .slider {
  width: 90% !important;
}

.range-slider-container.number-slider .slider .slider-handle {
  margin-left: -25px !important;
}

.range-slider-container.number-slider.slider-over .slider .slider-handle.min-slider-handle:after {
  content: "\f054" !important;
  left: 12px !important;
}

.range-slider-container .slider-description {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #232323;
  font-weight: normal;
  margin-bottom: 15px;
}

.range-slider-container .range-inputs .form-inline .form-group {
  max-width: 200px;
  margin-bottom: 10px;
}

.range-slider-container .range-inputs .form-inline .form-group label {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #232a30;
  font-weight: 600;
}

.range-slider-container .range-inputs .form-inline .form-group .input-group {
  max-width: 145px;
}

.range-slider-container .range-inputs .form-inline .form-group .input-group .input-group-addon {
  padding: 6px 6px;
  margin-left: 5px;
  background-color: #cccccc;
  color: #5b6268;
  color: #232a30;
}

.range-slider-container .range-inputs .form-inline .form-group .input-group input {
  color: #141719;
}

.range-slider-container .range-inputs .form-inline .form-group .input-group input.placeholder {
  color: #141719;
}

.range-slider-container .range-inputs .form-inline .form-group .input-group input:-moz-placeholder {
  color: #141719;
}

.range-slider-container .range-inputs .form-inline .form-group .input-group input::-moz-placeholder {
  color: #141719;
}

.range-slider-container .range-inputs .form-inline .form-group .input-group input:-ms-input-placeholder {
  color: #141719;
}

.range-slider-container .range-inputs .form-inline .form-group .input-group input::-webkit-input-placeholder {
  color: #141719;
}

.range-slider-container .range-inputs .form-inline .form-group:last-child label {
  margin: 0 10px;
}

.umb-grid .grid-section .video-grid-section {
  padding: 30px 15px;
}

.umb-grid .grid-section .video-grid-section .section-content h2 {
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  color: #000;
  font-weight: 600;
  display: block;
}

.umb-grid .grid-section .video-grid-section .section-content h3 {
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #232a30;
  font-weight: 600;
  margin: 0 0 25px;
  text-transform: uppercase;
  display: block;
}

.umb-grid .grid-section .video-grid-section .section-content .rte-content {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: normal;
  margin: 0 auto 30px;
  max-width: 1180px;
  text-align: center;
}

.umb-grid .grid-section .video-grid-section .section-video {
  max-width: 750px;
  margin: 0 auto;
}

.umb-grid .grid-section .video-grid-section .section-video .embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.umb-grid .grid-section .video-grid-section .section-video .embed-container iframe, .umb-grid .grid-section .video-grid-section .section-video .embed-container object, .umb-grid .grid-section .video-grid-section .section-video .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 640px) {
  .umb-grid .grid-section .video-grid-section .section-content h2 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .umb-grid .grid-section .video-grid-section .section-content h3 {
    font-size: 14px;
    line-height: 1.4;
  }
  .umb-grid .grid-section .video-grid-section .section-content .rte-content {
    text-align: justify;
  }
}

.umb-grid .grid-section .content-aside-section {
  background-color: #abc0c9;
  /*@media(min-width: 1400px) {
                .col-md-5 .content-container, .flex-col-md-5 .content-container {
                    width: 70%;
                }
            }

            @media(min-width: 1585px) {
                .col-md-5 .content-container, .flex-col-md-5 .content-container {
                    width: 65%;
                }
            }*/
}

.umb-grid .grid-section .content-aside-section .col-md-5, .umb-grid .grid-section .content-aside-section .flex-col-md-5 {
  display: flex;
  min-height: 400px;
  align-items: center;
}

.umb-grid .grid-section .content-aside-section .col-md-5 .content-container, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.umb-grid .grid-section .content-aside-section .col-md-5 .content-container h2, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 34px;
  color: #232a30;
  font-weight: 600;
}

.umb-grid .grid-section .content-aside-section .col-md-5 .content-container h3, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #232a30;
  font-weight: 600;
  margin: 12px 0 30px;
  text-transform: uppercase;
  line-height: 1.4;
}

.umb-grid .grid-section .content-aside-section .col-md-5 .content-container .aside-content, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container .aside-content {
  font-size: 15px;
  margin-bottom: 45px;
}

.umb-grid .grid-section .content-aside-section .col-md-5 .content-container .button a, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container .button a {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  padding: 15px 80px;
  background-color: #e6762c;
  color: #fff;
  text-shadow: 0px 0px 5px #9a4812;
  text-transform: uppercase;
  display: block;
  padding: 15px;
}

.umb-grid .grid-section .content-aside-section .col-md-5 .content-container .button a span:after, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container .button a span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.umb-grid .grid-section .content-aside-section .col-md-5 .content-container .button a:hover, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container .button a:hover {
  background-color: #c75e18;
}

.umb-grid .grid-section .content-aside-section .col-md-7, .umb-grid .grid-section .content-aside-section .flex-col-md-7 {
  padding-right: 0;
  text-align: right;
}

.umb-grid .grid-section .content-aside-section img {
  width: 100%;
  display: none;
}

.umb-grid .grid-section .content-aside-section .desktop-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 991px) {
  .umb-grid .grid-section .content-aside-section {
    /*swap positions*/
  }
  .umb-grid .grid-section .content-aside-section .flex-col-md-5 {
    padding-top: 0;
    min-height: 0px;
  }
  .umb-grid .grid-section .content-aside-section .desktop-img {
    display: none;
  }
  .umb-grid .grid-section .content-aside-section img {
    display: block;
    width: 100%;
  }
  .umb-grid .grid-section .content-aside-section .flex-col-md-5 {
    order: 1;
  }
}

@media (max-width: 767px) {
  .umb-grid .grid-section .content-aside-section .col-md-5 .content-container, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container {
    text-align: center;
    width: auto;
    padding: 0 15px 15px 15px;
  }
  .umb-grid .grid-section .content-aside-section .col-md-5 .content-container h2, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container h2 {
    font-size: 28px;
  }
  .umb-grid .grid-section .content-aside-section .col-md-5 .content-container h3, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container h3 {
    font-size: 14px;
    padding: 0 15px;
    margin-bottom: 20px;
  }
  .umb-grid .grid-section .content-aside-section .col-md-5 .content-container .aside-content, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container .aside-content {
    margin-bottom: 32px;
  }
}

@media (min-width: 992px) and (max-width: 1125px) {
  .umb-grid .grid-section .content-aside-section .flex-col-md-5 {
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
  }
  .umb-grid .grid-section .content-aside-section .flex-col-md-7 {
    flex: 0 0 50.333333%;
    max-width: 50.333333%;
  }
}

@media (min-width: 1125px) {
  .umb-grid .grid-section .content-aside-section .col-md-5 .content-container, .umb-grid .grid-section .content-aside-section .flex-col-md-5 .content-container {
    width: 400px;
  }
}

.umb-grid .grid-section .testimonial-section-container {
  margin: 0px 0;
  padding: 55px 15px 75px;
  width: 100%;
  background-color: #232a30;
}

.umb-grid .grid-section .testimonial-section-container h2 {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  color: #fff;
  font-weight: 600;
}

.umb-grid .grid-section .testimonial-section-container h3 {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #c7c7c7;
  font-weight: 600;
  margin: 15px 0 75px;
  text-transform: uppercase;
  line-height: 1.4;
}

.umb-grid .grid-section .testimonial-section-container .testimonials {
  text-align: center;
}

.umb-grid .grid-section .testimonial-section-container .testimonials .testimonial {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #e2e2e2;
  font-weight: normal;
  text-align: left;
}

.umb-grid .grid-section .testimonial-section-container .testimonials .testimonial:before {
  /*@include fontawesome-content("\f10d", #404b55, 35px, -5px, unset, unset, 20px);*/
  position: absolute;
  top: 3px;
  padding-right: .5em;
  right: unset;
  bottom: unset;
  left: 20px;
  display: block;
  content: " ";
  background-image: url("/dist/img/testimonial-quote-icon-light.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}

.umb-grid .grid-section .testimonial-section-container .testimonials .testimonial-author {
  text-align: right;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  padding: 20px 0 0;
}

.umb-grid .grid-section .testimonial-section-container .testimonials .col-md-6 .testimonial-container {
  width: 90%;
  float: right;
}

@media (max-width: 991px) {
  .umb-grid .grid-section .testimonial-section-container .testimonials .col-md-6 {
    display: none;
  }
  .umb-grid .grid-section .testimonial-section-container .testimonials .col-md-6:first-child {
    display: block;
  }
}

.umb-grid .grid-section .testimonial-section-container .testimonials-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 10px 20px;
  background-color: #e6762c;
  color: #fff;
  text-shadow: 0px 0px 5px #9a4812;
  margin: 75px 0 0;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.umb-grid .grid-section .testimonial-section-container .testimonials-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.umb-grid .grid-section .testimonial-section-container .testimonials-btn:hover {
  background-color: #c75e18;
}

.umb-grid .grid-section .testimonial-section-container.compact-mode {
  background-color: #abc0c9;
  text-align: center;
  padding: 55px 15px 55px;
}

.umb-grid .grid-section .testimonial-section-container.compact-mode .container .row {
  display: flex;
}

.umb-grid .grid-section .testimonial-section-container.compact-mode .container .row .titles {
  width: 40%;
}

.umb-grid .grid-section .testimonial-section-container.compact-mode .container .row .titles h2 {
  color: #141719;
  font-size: 26px;
  text-align: left;
}

.umb-grid .grid-section .testimonial-section-container.compact-mode .container .row .titles h3 {
  color: #232a30;
  font-size: 14px;
  text-align: left;
  margin-bottom: 0;
}

.umb-grid .grid-section .testimonial-section-container.compact-mode .container .row .testimonials {
  position: relative;
  width: 60%;
  padding-right: 15px;
}

.umb-grid .grid-section .testimonial-section-container.compact-mode .container .row .testimonials .testimonial {
  color: #232323;
  margin-left: 80px;
}

.umb-grid .grid-section .testimonial-section-container.compact-mode .container .row .testimonials .testimonial:before {
  background-image: url("/dist/img/testimonial-quote-icon-dark.svg");
}

.umb-grid .grid-section .testimonial-section-container.compact-mode .container .row .testimonials .testimonial-author {
  color: #141719;
}

.umb-grid .grid-section .testimonial-section-container.compact-mode .testimonials-btn {
  margin: 0px 0 0;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .umb-grid .grid-section .testimonial-section-container h2 {
    font-size: 28px;
  }
  .umb-grid .grid-section .testimonial-section-container .testimonials .col-md-6 .testimonial-container .testimonial:before {
    left: 0px;
  }
}

@media (max-width: 620px) {
  .umb-grid .grid-section .testimonial-section-container .testimonials .col-md-6 {
    padding-left: 0;
    padding-right: 0;
  }
  .umb-grid .grid-section .testimonial-section-container .testimonials .col-md-6 .testimonial-container {
    width: 100%;
  }
  .umb-grid .grid-section .testimonial-section-container .testimonials .col-md-6 .testimonial-container .testimonial::before {
    top: -35px;
  }
}

@media (max-width: 767px) {
  .umb-grid .grid-section .testimonial-section-container.compact-mode .container .row {
    display: block;
  }
  .umb-grid .grid-section .testimonial-section-container.compact-mode .container .row .titles {
    width: 100%;
    margin-bottom: 2em;
  }
  .umb-grid .grid-section .testimonial-section-container.compact-mode .container .row .testimonials {
    width: 100%;
  }
}

.check-availability-link {
  width: 100%;
}

.check-availability-link a {
  font-weight: 600 !important;
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 auto;
  font-size: 20px !important;
  text-align: center !important;
  border-color: #ce301a !important;
  margin-bottom: 10px !important;
  width: 95% !important;
  background-color: #ce301a !important;
  color: white !important;
}

.check-availability-link a:hover {
  background-color: #9f2414 !important;
  color: white !important;
}

.vehicle-links {
  position: relative;
  margin-top: 0 !important;
  /*override new colors*/
}

.vehicle-links .col {
  margin: 0;
  padding: 0;
  background-color: #c7c7c7;
  border-right: solid 1px #f2f2f2;
}

.vehicle-links .col a {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #232a30;
  font-weight: 600;
  text-align: center;
  width: 100%;
  display: block;
  padding: 15px 0;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
}

.vehicle-links .col a span.fa {
  margin-right: 2px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.vehicle-links .col a.vehicle-like span.active {
  -webkit-animation: pulse 0.5s linear;
  animation: pulse 0.5s linear;
  /*color: $endColor;*/
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.vehicle-links .col a.vehicle-compare span.active {
  -webkit-animation: pulse 0.5s linear;
  animation: pulse 0.5s linear;
  /*color: $endColor;*/
}

.vehicle-links .col a:hover {
  background-color: #aeaeae;
  text-decoration: none;
}

.vehicle-links .col a:active, .vehicle-links .col a:focus {
  text-decoration: none;
}

.vehicle-links .col:last-child {
  border-right: none;
}

.vehicle-links .addthis-container {
  position: absolute;
  top: -63px;
  width: 100%;
  display: none;
}

.vehicle-links .addthis-container .share-arrow {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #ccc;
  right: 16px;
  position: absolute;
  top: 60px;
}

.vehicle-links .addthis-container .share-content {
  background-color: #ccc;
  padding: 5px;
}

.vehicle-links .addthis-container .share-content .a2a_default_style {
  width: 100%;
  text-align: right;
}

.vehicle-links .addthis-container .share-content .a2a_default_style a {
  width: auto;
  display: inline-block;
  padding: 10px;
  background-color: unset !important;
  float: right !important;
}

.vehicle-links .col-btn.vehicle-compare .check-square::before {
  content: "\f0c8";
}

.vehicle-links .col-btn.active.vehicle-compare {
  background-color: #31728e;
}

.vehicle-links .col-btn.active.vehicle-compare a:hover {
  background-color: transparent;
}

.vehicle-links .col-btn.active.vehicle-compare a {
  color: #fff;
}

.vehicle-links .col-btn.active.vehicle-compare .check-square::before {
  content: "\f14a";
}

.vehicle-links .col-btn.active.vehicle-like {
  background-color: #cd3412;
}

.vehicle-links .col-btn.active.vehicle-like a:hover {
  background-color: transparent;
}

.vehicle-links .col-btn.active.vehicle-like a {
  color: #fff;
}

.vehicle-links .col a {
  background-color: #5b6773;
  color: #fff;
}

.vehicle-links .col a:hover {
  background-color: #778592;
  color: #fff;
}

.vehicle-links .col-btn.active.vehicle-compare a {
  background-color: #31728e;
  color: #fff;
}

.vehicle-links .col-btn.active.vehicle-like a {
  background-color: #cd3412;
  color: #fff;
}

.vehicle-card {
  background-color: #f2f2f2;
}

.vehicle-card .image {
  background-color: #fff;
  position: relative;
  min-height: 200px;
  max-height: 328px;
  overflow: hidden;
}

.vehicle-card .image img {
  width: 100%;
}

.vehicle-card .image img.photo-overlay {
  position: absolute;
}

.vehicle-card .vehicle-content .dealer-network {
  color: #E6762C;
  font-size: 12px;
}

.vehicle-card .vehicle-content .name {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  color: #000;
  font-weight: 700;
  padding: 15px 20px 10px;
}

.vehicle-card .vehicle-content .name a {
  color: #000;
}

.vehicle-card .vehicle-content .name .color-mileage, .vehicle-card .vehicle-content .name .stock-number {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  padding-top: 2px;
  vertical-align: middle;
  color: #333d46 !important;
}

.vehicle-card .vehicle-content .name .color-mileage .bullet, .vehicle-card .vehicle-content .name .stock-number .bullet {
  vertical-align: middle;
}

.vehicle-card .vehicle-content .name .color-mileage .bullet::before, .vehicle-card .vehicle-content .name .stock-number .bullet::before {
  content: "\f111";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  color: #2f728c;
  font-size: 6px;
  padding-left: .5em;
  padding-right: .5em;
  vertical-align: middle;
  padding-bottom: 0.35em;
  display: inline-block;
}

.vehicle-card .vehicle-content .price-mileage {
  padding: 0 20px 15px;
  min-height: 88px;
  /*.price-mileage-content {
                color: #1e4566;
                font-size: 24px;
                font-weight: 600;
                line-height: 24px;
                font-family: $roboto-font;
            }*/
  /*@media(max-width: 1330px) {
                .price-mileage-content {
                    font-size: 16px;
                }
            }*/
}

.vehicle-card .vehicle-content .price-mileage .price-mileage-label {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #000;
  position: absolute;
  bottom: 0;
}

@media (max-width: 991px) {
  .vehicle-card .vehicle-content .price-mileage .price-mileage-label {
    margin-bottom: 10px;
  }
}

.vehicle-card .vehicle-content .price-mileage .price-mileage {
  padding-top: 12px;
  padding-bottom: 24px;
}

.vehicle-card .vehicle-content .price-mileage .col-md-3, .vehicle-card .vehicle-content .price-mileage .col-md-4, .vehicle-card .vehicle-content .price-mileage .col-md-5, .vehicle-card .vehicle-content .price-mileage .col-md-6, .vehicle-card .vehicle-content .price-mileage .col-md-7, .vehicle-card .vehicle-content .price-mileage .col-md-8 {
  min-height: 47px;
  position: relative;
}

.vehicle-card .vehicle-content .price-mileage .col-md-4 {
  margin-bottom: 0 !important;
}

.vehicle-card .vehicle-content .price-mileage .col, .vehicle-card .vehicle-content .price-mileage .col-md-6 {
  margin: 0;
  padding: 0;
}

.vehicle-card .vehicle-content .price-mileage .col-md-6 {
  float: left;
  width: 50%;
}

.vehicle-card .vehicle-content .price-mileage .price-mileage-content {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  color: #1e4566;
  font-weight: 600;
}

.vehicle-card .vehicle-content .price-mileage .price-mileage-content.financing {
  font-size: 18px !important;
  color: #1e4668 !important;
  display: block;
  padding-top: 3px;
}

.vehicle-card .vehicle-content .price-mileage .price-mileage-content.price {
  color: #cd3412 !important;
}

.vehicle-card .vehicle-content .price-mileage .price-mileage-content.msrp {
  color: #000 !important;
}

.vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-4 {
  width: 26.33333%;
  padding-left: 0;
}

.vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-4 .price-mileage-content {
  color: #000;
  text-decoration: line-through;
}

.vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 {
  border-left: dotted 1px #000;
  padding-left: 20px;
}

.vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-7, .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-6 {
  padding: 0;
}

.vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-7 .unlock-btn, .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-6 .unlock-btn {
  padding: 3px 3px;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
  cursor: pointer;
}

.vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-7 .unlock-btn span:after, .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-6 .unlock-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-7 .unlock-btn:hover, .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-6 .unlock-btn:hover {
  background-color: #a5341c;
}

.vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-5.hidden-price-content {
  padding: 0 0 0 5px;
}

.vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-5.hidden-price-content span.hidden-payment {
  height: 8px;
  border-top: dotted 1px #000;
  width: 42px;
  border-top-width: 2px;
  margin-left: 5px;
  display: inline-block;
  width: 30px;
}

@media (max-width: 991px) {
  .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-4 {
    float: left;
  }
  .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 {
    border-left: unset;
    padding-left: 0;
    width: 68.99999%;
    float: left;
  }
  .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-5 {
    padding-left: 0 !important;
  }
  .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-7, .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-5 {
    width: 48.99999%;
    float: left;
  }
  .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-7 .price-mileage-content, .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 .col-md-5 .price-mileage-content {
    display: block;
    margin-top: 0;
  }
}

@media (max-width: 441px) {
  .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-4 {
    float: none;
    margin-bottom: 10px;
  }
  .vehicle-card .vehicle-content .price-mileage.new-vehicle .col-md-8 {
    width: 100%;
  }
}

.vehicle-card .vehicle-content .price-mileage.used-vehicle .col-md-4, .vehicle-card .vehicle-content .price-mileage.used-vehicle .col-md-3 {
  padding-left: 0;
}

.vehicle-card .vehicle-content .price-mileage.used-vehicle .col-md-4:last-child, .vehicle-card .vehicle-content .price-mileage.used-vehicle .col-md-3:last-child {
  padding-right: 0;
}

.vehicle-card .vehicle-content .price-mileage.used-vehicle .col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

.vehicle-card .vehicle-content .price-mileage.used-vehicle .col-md-12 .good-deal {
  border: solid 1px #DDDCDC;
  background-color: #fff;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  margin: 15px 0 0;
  width: 230px;
  /*@media(max-width: 1130px) {
                            margin-top: 10px;
                        }*/
}

.vehicle-card .vehicle-content .price-mileage.used-vehicle .col-md-12 .good-deal .thumbs {
  float: left;
  position: absolute;
  top: 10px;
  left: 10px;
}

.vehicle-card .vehicle-content .price-mileage.used-vehicle .col-md-12 .good-deal .thumbs-text {
  font-weight: bold;
  padding-left: 20px;
  font-family: "Montserrat", sans-serif;
}

.vehicle-card .vehicle-content .price-mileage.used-vehicle .col-md-12 .good-deal .thumbs-text a {
  border-bottom: dotted 1px #337ab7;
  cursor: pointer;
  display: inline-block;
  padding-left: 5px;
}

@media (max-width: 1744px) {
  .vehicle-card .vehicle-content .price-mileage .col-md-4 {
    width: 30.33333%;
  }
  .vehicle-card .vehicle-content .price-mileage .col-md-8 .col-md-7 {
    width: 48.33333%;
  }
}

@media (max-width: 1630px) {
  .vehicle-card .vehicle-content .price-mileage .price-mileage-content {
    font-size: 20px;
  }
}

@media (max-width: 1130px) {
  .vehicle-card .vehicle-content .price-mileage .col-md-3, .vehicle-card .vehicle-content .price-mileage .col-md-4, .vehicle-card .vehicle-content .price-mileage .col-md-5, .vehicle-card .vehicle-content .price-mileage .col-md-6, .vehicle-card .vehicle-content .price-mileage .col-md-7, .vehicle-card .vehicle-content .price-mileage .col-md-8 {
    float: none !important;
    width: 100% !important;
  }
  .vehicle-card .vehicle-content .price-mileage .col-md-3 .price-mileage-label, .vehicle-card .vehicle-content .price-mileage .col-md-4 .price-mileage-label, .vehicle-card .vehicle-content .price-mileage .col-md-5 .price-mileage-label, .vehicle-card .vehicle-content .price-mileage .col-md-6 .price-mileage-label, .vehicle-card .vehicle-content .price-mileage .col-md-7 .price-mileage-label, .vehicle-card .vehicle-content .price-mileage .col-md-8 .price-mileage-label {
    position: relative !important;
  }
  .vehicle-card .vehicle-content .price-mileage .col-md-8 {
    padding-left: 0 !important;
    border-left: none !important;
  }
  .vehicle-card .vehicle-content .price-mileage .col-md-8 .col-md-5 {
    padding: 0 !important;
  }
}

@media (max-width: 815px) {
  .vehicle-card .vehicle-content .price-mileage .price-mileage-content {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .vehicle-card .vehicle-content .price-mileage .price-mileage-content {
    font-size: 24px;
  }
}

.vehicle-card .vehicle-highlights {
  padding: 0 20px 10px;
}

.vehicle-card .vehicle-highlights .highlights-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #232a30;
  font-weight: 600;
}

.vehicle-card .vehicle-highlights ul {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: normal;
  padding-left: 18px;
  margin-top: 6px;
  list-style: none;
}

.vehicle-card .vehicle-highlights ul li {
  position: relative;
}

.vehicle-card .vehicle-highlights ul li:before {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  /*--adjust as necessary--*/
  color: #2f728c;
  font-size: 6px;
  padding-right: 0.5em;
  position: absolute;
  top: 5px;
  right: unset;
  bottom: unset;
  left: -14px;
}

.vehicle-card .stratosLaunchButton.trade-btn {
  font-weight: 600 !important;
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 auto;
}

.vehicle-card .vehicle-conditionstock::after {
  display: block;
  content: "";
  clear: both;
}

.vehicle-card .vehicle-conditionstock ul {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0 20px;
}

.vehicle-card .vehicle-conditionstock ul li {
  float: left;
  width: 50%;
  margin: 0 0 15px;
  padding: 6px 0;
  text-align: center;
  background-color: #CCCACA;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #000;
  font-weight: 500;
}

.vehicle-card .vehicle-conditionstock ul li span.heading {
  font-weight: bold;
}

.vehicle-card .vehicle-conditionstock ul li:last-child {
  background-color: #DDDCDC;
}

.vehicle-card .vehicle-conditionstock ul li.network {
  width: 100% !important;
}

.vehicle-card .vehicle-buttons {
  margin-bottom: 15px;
}

.vehicle-card .vehicle-buttons .col button {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}

.vehicle-card .vehicle-buttons .col button.pre-qualify {
  padding: 10px 0;
  background-color: #ce301a;
  color: #fff;
  text-shadow: 0px 0px 5px #731b0f;
  width: 100%;
}

.vehicle-card .vehicle-buttons .col button.pre-qualify span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.vehicle-card .vehicle-buttons .col button.pre-qualify:hover {
  background-color: #a12514;
}

.vehicle-card .vehicle-buttons .col button.view-details {
  padding: 10px 0;
  background-color: #2e7d9c;
  color: #fff;
  text-shadow: 0px 0px 5px #173e4d;
  /*#3690b4*/
  width: 100%;
}

.vehicle-card .vehicle-buttons .col button.view-details span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.vehicle-card .vehicle-buttons .col button.view-details:hover {
  background-color: #225d75;
}

.vehicle-card .vehicle-buttons .col:first-child {
  padding-right: 5px;
}

.vehicle-card .vehicle-buttons .col:last-child {
  padding-left: 5px;
}

@media (min-width: 991px) and (max-width: 1098px) {
  .vehicle-card .vehicle-buttons .col button {
    font-size: 14px;
  }
}

@media (min-width: 1259px) and (max-width: 1440px) {
  .vehicle-card .vehicle-buttons .col button {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .vehicle-card .vehicle-buttons .col, .vehicle-card .vehicle-buttons .col:first-child, .vehicle-card .vehicle-buttons .col:last-child {
    padding-right: 0;
    padding-left: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
  .vehicle-card .vehicle-buttons .col:first-child {
    margin-bottom: 10px;
  }
}

@media (max-width: 991px) {
  .vehicle-card {
    /* try vehicle links */
  }
  .vehicle-card .vehicle-buttons .col, .vehicle-card .vehicle-buttons .col:first-child, .vehicle-card .vehicle-buttons .col:last-child {
    width: 50%;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .vehicle-card .vehicle-buttons .col:first-child, .vehicle-card .vehicle-buttons .col:first-child:first-child, .vehicle-card .vehicle-buttons .col:last-child:first-child {
    padding-right: 5px;
  }
  .vehicle-card .vehicle-buttons .col:last-child, .vehicle-card .vehicle-buttons .col:first-child:last-child, .vehicle-card .vehicle-buttons .col:last-child:last-child {
    padding-left: 5px;
  }
  .vehicle-card .vehicle-buttons .col button, .vehicle-card .vehicle-buttons .col:first-child button, .vehicle-card .vehicle-buttons .col:last-child button {
    font-size: 14px;
  }
  .vehicle-card .vehicle-links .col.col-md-6 {
    float: left;
    width: 50%;
  }
  .vehicle-card .vehicle-links .col.col-md-4 {
    width: 33.33333%;
    float: left;
  }
}

@media (min-width: 945px) and (max-width: 991px) {
  .vehicle-card .vehicle-links .col-md-4 a {
    font-size: 12px;
  }
}

@media (min-width: 620px) and (max-width: 640px) {
  .vehicle-card .vehicle-links .col-md-4 a {
    font-size: 12px;
  }
}

/* Comparison Popup*/
.vehicle-comparison-fixed {
  position: fixed;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #141719;
  color: #fff;
  transition: margin 0.3s;
  z-index: 999;
  border-top: 1px solid #5b6268;
}

.vehicle-comparison-fixed.minimized {
  margin-bottom: -98px;
}

.vehicle-comparison-fixed .title {
  text-align: center;
  color: #e6762c;
  text-transform: uppercase;
}

.vehicle-comparison-fixed .title:hover {
  cursor: pointer;
}

.vehicle-comparison-fixed .minimize {
  padding-left: 1em;
  padding-right: 1em;
  color: #fff;
}

.vehicle-comparison-fixed .minimize:hover {
  cursor: pointer;
}

.vehicle-comparison-fixed .flex-row {
  margin-bottom: 10px;
}

.vehicle-comparison-fixed .flex-row .vehicles-list .vehicle {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  background-color: #33373a;
  border: 1px solid white;
  margin-right: 15px;
  background-size: cover;
  background-position: center center;
}

.vehicle-comparison-fixed .flex-row .vehicles-list .vehicle a.url {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vehicle-comparison-fixed .flex-row .vehicles-list .vehicle .close-vehicle {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 2px 3px;
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.vehicle-comparison-fixed .flex-row .vehicles-list .vehicle .close-vehicle:hover {
  background-color: #000;
  color: #fff;
  text-decoration: none;
}

.vehicle-comparison-fixed .flex-row .rules {
  justify-content: space-around;
  position: relative;
}

.vehicle-comparison-fixed .flex-row .rules .select-text {
  font-size: 17px;
}

.vehicle-comparison-fixed .flex-row .rules .bottom {
  position: absolute;
  bottom: 4px;
  width: 100%;
}

.vehicle-comparison-fixed .flex-row .rules .bottom a.clear-all {
  vertical-align: bottom;
  color: #40a5d0;
  border-bottom: 1px dotted #31728e;
}

.vehicle-comparison-fixed .flex-row .rules .bottom a.clear-all:hover {
  color: #23637e;
  border-bottom: 1px solid #23637e;
  text-decoration: none;
}

.vehicle-comparison-fixed .flex-row .rules .bottom a.btn.compare, .vehicle-comparison-fixed .flex-row .rules .bottom span.btn.compare {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  background-color: #2e7d9c;
  color: #fff;
  text-shadow: 0px 0px 5px #173e4d;
  /*#3690b4*/
  background-color: #31728e;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.vehicle-comparison-fixed .flex-row .rules .bottom a.btn.compare span:after, .vehicle-comparison-fixed .flex-row .rules .bottom span.btn.compare span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.vehicle-comparison-fixed .flex-row .rules .bottom a.btn.compare:hover, .vehicle-comparison-fixed .flex-row .rules .bottom span.btn.compare:hover {
  background-color: #225d75;
}

.vehicle-comparison-fixed .flex-row .rules .bottom a.btn.compare:hover, .vehicle-comparison-fixed .flex-row .rules .bottom span.btn.compare:hover {
  background-color: #23637e;
}

.vehicle-comparison-fixed .flex-row .rules .bottom a.btn.compare:active, .vehicle-comparison-fixed .flex-row .rules .bottom span.btn.compare:active {
  background-color: #155771;
}

.vehicle-comparison-fixed .flex-row .rules .bottom span.btn.compare.disabled {
  background-color: #b2b2b4;
  color: #fff;
  opacity: 1;
}

.vehicle-comparison-fixed .flex-row .rules .bottom .loading {
  background-image: url("/dist/img/bx_loader.gif");
  background-size: 100%;
  height: 12px;
  width: 12px;
  margin-left: 5px;
  vertical-align: middle;
  display: none;
}

.vehicle-comparison-fixed .flex-row .rules .bottom .loading.is-loading {
  display: inline-block;
}

@media (min-width: 1200px) {
  .vehicle-comparison-fixed .container {
    width: 970px;
  }
}

@media (max-width: 784px) {
  .vehicle-comparison-fixed.minimized {
    margin-bottom: -155px;
  }
  .vehicle-comparison-fixed .flex-row {
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
  }
  .vehicle-comparison-fixed .flex-row .vehicles-list {
    text-align: center;
  }
  .vehicle-comparison-fixed .flex-row .vehicles-list .vehicle:last-child {
    margin-right: 0px;
  }
  .vehicle-comparison-fixed .flex-row .rules {
    width: 460px;
    margin-left: auto;
    margin-right: auto;
  }
  .vehicle-comparison-fixed .flex-row .rules .bottom {
    position: relative;
    display: block;
    bottom: inherit;
  }
  .vehicle-comparison-fixed .flex-row .rules .bottom a.clear-all {
    float: left;
    margin-top: 5px;
  }
  .vehicle-comparison-fixed .flex-row .rules .bottom a.btn.compare, .vehicle-comparison-fixed .flex-row .rules .bottom span.btn.compare {
    position: relative;
    padding: 5px 10px;
    float: right;
  }
}

@media (max-width: 640px) {
  .vehicle-comparison-fixed.minimized {
    margin-bottom: -120px;
  }
  .vehicle-comparison-fixed .flex-row .vehicles-list {
    display: flex;
    text-align: center;
  }
  .vehicle-comparison-fixed .flex-row .vehicles-list .vehicle {
    flex: 1 1 auto;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .vehicle-comparison-fixed .flex-row .rules {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .vehicle-comparison-fixed .flex-row .rules .select-text {
    font-size: 14px;
    margin-top: 5px;
  }
}

.umb-grid .grid-section .offers-slider-container, .umb-grid .grid-section .featured-vehicle-slider-container {
  padding-left: 50px;
  padding-right: 50px;
}

.umb-grid .grid-section .offers-slider-container img, .umb-grid .grid-section .featured-vehicle-slider-container img {
  max-width: 100%;
}

.umb-grid .grid-section .offers-slider-container .vehicle-card, .umb-grid .grid-section .featured-vehicle-slider-container .vehicle-card {
  margin-left: 5px;
  margin-right: 5px;
  background-color: #f2f2f2;
}

.umb-grid .grid-section .offers-slider-container .slick-prev:before, .umb-grid .grid-section .offers-slider-container .slick-next:before, .umb-grid .grid-section .featured-vehicle-slider-container .slick-prev:before, .umb-grid .grid-section .featured-vehicle-slider-container .slick-next:before {
  color: transparent;
}

.umb-grid .grid-section .offers-slider-container .slick-prev, .umb-grid .grid-section .featured-vehicle-slider-container .slick-prev {
  left: -40px;
  background-image: url("/dist/img/left_chevron.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 68px;
  opacity: 0.91;
}

.umb-grid .grid-section .offers-slider-container .slick-next, .umb-grid .grid-section .featured-vehicle-slider-container .slick-next {
  right: -40px;
  background-image: url("/dist/img/right_chevron.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 68px;
  opacity: 0.7;
}

.umb-grid .grid-section .offers-slider-container .slick-prev:hover, .umb-grid .grid-section .offers-slider-container .slick-next:hover, .umb-grid .grid-section .featured-vehicle-slider-container .slick-prev:hover, .umb-grid .grid-section .featured-vehicle-slider-container .slick-next:hover {
  opacity: 1;
}

.umb-grid .grid-section .offers-slider-container .slick-prev:focus, .umb-grid .grid-section .offers-slider-container .slick-next:focus, .umb-grid .grid-section .featured-vehicle-slider-container .slick-prev:focus, .umb-grid .grid-section .featured-vehicle-slider-container .slick-next:focus {
  color: #000;
}

.umb-grid .grid-section .offers-slider-container .slick-prev.slick-disabled, .umb-grid .grid-section .offers-slider-container .slick-next.slick-disabled, .umb-grid .grid-section .featured-vehicle-slider-container .slick-prev.slick-disabled, .umb-grid .grid-section .featured-vehicle-slider-container .slick-next.slick-disabled {
  opacity: 0.25;
}

@media (max-width: 767px) {
  .umb-grid .grid-section .offers-slider-container, .umb-grid .grid-section .featured-vehicle-slider-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .umb-grid .grid-section .offers-slider-container .slick-prev, .umb-grid .grid-section .featured-vehicle-slider-container .slick-prev {
    left: -26px;
    width: 24px;
    height: 55px;
  }
  .umb-grid .grid-section .offers-slider-container .slick-next, .umb-grid .grid-section .featured-vehicle-slider-container .slick-next {
    right: -26px;
    width: 24px;
    height: 55px;
  }
}

.umb-grid .grid-section .slick-dots {
  bottom: -45px;
}

.umb-grid .grid-section .slick-dots li {
  margin: 0;
}

.umb-grid .grid-section .slick-dots li button {
  border: 1px solid #b4bfc5;
  background-color: #e2e2e2;
  border-radius: 10px;
  height: 10px;
  width: 10px;
}

.umb-grid .grid-section .slick-dots li button:before {
  color: transparent;
  content: "";
}

.umb-grid .grid-section .slick-dots li button:focus {
  color: #000;
}

.umb-grid .grid-section .slick-dots li.slick-active button {
  border: 1px solid #212426;
  background-color: #232a30;
}

.umb-grid .grid-section .slick-list {
  -ms-transform: translateZ(0);
}

.umb-grid .grid-section .featured-vehicle-container {
  padding: 30px 15px;
}

.umb-grid .grid-section .featured-vehicle-container .featured-vehicle-slider-container .vehicle-card {
  margin-left: 5px;
  margin-right: 5px;
  background-color: #f2f2f2;
}

.umb-grid .grid-section .featured-vehicle-container .featured-vehicle-slider-container .vehicle-card .tooltip {
  width: 200px;
}

.umb-grid .grid-section .featured-vehicle-container .row {
  position: relative;
}

.umb-grid .grid-section .featured-vehicle-container .row .col-md-12 h2 {
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  color: #000;
  font-weight: 600;
  display: block;
}

.umb-grid .grid-section .featured-vehicle-container .row .col-md-12 h3 {
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #232a30;
  font-weight: 600;
  margin: 0 0 25px;
  text-transform: uppercase;
  display: block;
  line-height: 1.4;
}

.umb-grid .grid-section .featured-vehicle-container .row .col-md-3 {
  padding: 0 5px;
}

@media (max-width: 945px) {
  .umb-grid .grid-section .featured-vehicle-container .featured-vehicle-slider-container {
    padding-bottom: 4px;
  }
  .umb-grid .grid-section .featured-vehicle-container .slick-dots {
    bottom: -45px;
  }
}

@media (max-width: 767px) {
  .umb-grid .grid-section .featured-vehicle-container .row .col-md-12 h2 {
    font-size: 28px;
  }
}

@media (max-width: 620px) {
  .umb-grid .grid-section .featured-vehicle-container .featured-vehicle-slider-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .umb-grid .grid-section .featured-vehicle-container .featured-vehicle-slider-container .slick-arrow {
    display: none !important;
  }
}

.umb-grid .grid-section .offers-slider-container {
  /*@include margin(15px 0);
            @include padding(30px 0 60px);
            width: 100%;*/
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1470px;
}

.umb-grid .grid-section .offers-slider-container h2 {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 34px;
  color: #000;
  font-weight: 600;
}

.umb-grid .grid-section .offers-slider-container h3 {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #232a30;
  font-weight: 600;
  margin: 15px 0 30px;
  text-transform: uppercase;
  line-height: 1.3;
}

.umb-grid .grid-section .offers-slider-container .row {
  text-align: center;
}

.umb-grid .grid-section .offers-slider-container .row.offers-slider-row {
  margin-left: 0;
  margin-right: 0;
}

.umb-grid .grid-section .offers-slider-container .row.offers-slider-row .slick-track {
  display: flex;
  align-items: center;
}

.umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider {
  display: block;
}

.umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider-mobile, .umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider-tablet {
  display: none;
}

.umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider-mobile .item img, .umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider-tablet .item img {
  width: 100%;
}

@media (max-width: 767px) {
  .umb-grid .grid-section .offers-slider-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .umb-grid .grid-section .offers-slider-container h2 {
    font-size: 28px;
  }
  .umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider {
    display: none;
  }
  .umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider-tablet {
    display: block;
  }
}

@media (max-width: 500px) {
  .umb-grid .grid-section .offers-slider-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .umb-grid .grid-section .offers-slider-container h2 {
    font-size: 28px;
  }
  .umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider, .umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider-tablet {
    display: none;
  }
  .umb-grid .grid-section .offers-slider-container .row.offers-slider-row .offers-slider-mobile {
    display: block;
  }
}

.umb-grid .grid-section .col-md-3 .offers-slider-container {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

.umb-grid .grid-section .col-md-3 .offers-slider-container h2, .umb-grid .grid-section .col-md-3 .offers-slider-container h3 {
  font-size: 18px;
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: left;
  color: #000;
}

.umb-grid .grid-section .col-md-3 .offers-slider-container .row.offers-slider-row .offers-slider {
  display: none;
}

.umb-grid .grid-section .col-md-3 .offers-slider-container .row.offers-slider-row .offers-slider-mobile {
  display: block;
}

.umb-grid .grid-section .col-md-3 .offers-slider-container .slick-prev {
  height: 32px;
  width: 32px;
  left: -20px;
}

.umb-grid .grid-section .col-md-3 .offers-slider-container .slick-next {
  height: 32px;
  width: 32px;
  right: -36px;
}

.umb-grid .grid-section .dealer-location-section {
  margin: 0;
  padding: 50px 15px;
  background-color: #e0dfe1;
}

.umb-grid .grid-section .dealer-location-section h2 {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  color: #000;
  font-weight: 600;
}

.umb-grid .grid-section .dealer-location-section h3 {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #232a30;
  font-weight: 600;
  margin: 15px 0 30px;
  text-transform: uppercase;
  line-height: 1.4;
}

.umb-grid .grid-section .dealer-location-section .row .col-md-12.dealer-logos {
  width: 100%;
  text-align: center;
  padding-top: 5px;
}

.umb-grid .grid-section .dealer-location-section .row .col-md-12.dealer-logos .dealer-logo-item {
  padding: 15px 20px;
  display: inline-block;
  width: 180px;
}

.umb-grid .grid-section .dealer-location-section .row .col-md-12.dealer-logos .dealer-logo-item img {
  max-width: 100%;
}

@media (max-width: 970px) {
  .umb-grid .grid-section .dealer-location-section .row .col-md-12.dealer-logos .dealer-logo-item {
    width: 33.333%;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 767px) {
  .umb-grid .grid-section .dealer-location-section .row .col-md-12.dealer-logos .dealer-logo-item {
    width: 50%;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 767px) {
  .umb-grid .grid-section .dealer-location-section h2 {
    font-size: 28px;
  }
}

#nav-search-dynamic-popup {
  display: none;
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1010;
}

#nav-search-dynamic-popup.active {
  display: flex;
}

#nav-search-dynamic-popup .overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#nav-search-dynamic-popup .inner-popup {
  /*position: fixed;
        top: 50%;
        left: 50%;
        display: inline-block;
        transform: translateY(-50%) translateX(-50%);*/
  width: 75%;
  display: block;
  z-index: 2;
  max-height: 100vh;
  overflow-y: auto;
  position: relative;
}

#nav-search-dynamic-popup .inner-popup .btn-close {
  display: block;
  font-size: 34px;
  padding: 5px;
  position: absolute;
  top: 0px;
  right: 12px;
  z-index: 2;
}

#nav-search-dynamic-popup .inner-popup .btn-close:hover {
  cursor: pointer;
  color: black;
}

#nav-search-dynamic-popup .inner-popup .inner-content {
  background-color: white;
  padding: 30px;
  position: relative;
}

#nav-search-dynamic-popup .inner-popup .inner-content .searched-for {
  margin-bottom: 2em;
}

#nav-search-dynamic-popup .inner-popup .inner-content .title {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}

#nav-search-dynamic-popup .inner-popup .inner-content .subtitle {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
}

#nav-search-dynamic-popup .inner-popup .inner-content a {
  border-bottom: 1px dotted #337ab7;
}

#nav-search-dynamic-popup .inner-popup .inner-content a:hover {
  text-decoration: none;
  border-bottom: 1px solid #22527b;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items .item {
  padding: 5px;
  display: block;
  max-width: 300px;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items .item input[type="checkbox"] {
  display: none;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items .item label {
  border: 2px solid transparent;
  padding: 10px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items .item label:hover {
  cursor: pointer;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items .item label.active {
  border: 2px solid #3690b4;
  border-radius: 5px;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items .item label img {
  max-width: 80%;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items .item label .count {
  font-weight: normal;
  display: block;
  margin-top: -0.15em;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items .item:hover label {
  border: 2px solid #cccccc;
  border-radius: 5px;
}

#nav-search-dynamic-popup .inner-popup .inner-content .items .item:hover label.active {
  border-color: #3690b4;
}

#nav-search-dynamic-popup .inner-popup .inner-content .buttons a.orange-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 10px 55px;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
  background-color: #5b6268;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 15px;
  white-space: normal;
}

#nav-search-dynamic-popup .inner-popup .inner-content .buttons a.orange-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#nav-search-dynamic-popup .inner-popup .inner-content .buttons a.orange-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#nav-search-dynamic-popup .inner-popup .inner-content .buttons a.orange-btn:hover {
  background-color: #a5341c;
}

#nav-search-dynamic-popup .inner-popup .inner-content .buttons a.orange-btn:hover {
  background-color: #5b6268;
}

#nav-search-dynamic-popup .inner-popup .inner-content.selected .buttons a.orange-btn {
  padding: 10px 55px;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
}

#nav-search-dynamic-popup .inner-popup .inner-content.selected .buttons a.orange-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#nav-search-dynamic-popup .inner-popup .inner-content.selected .buttons a.orange-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#nav-search-dynamic-popup .inner-popup .inner-content.selected .buttons a.orange-btn:hover {
  background-color: #a5341c;
}

#nav-search-dynamic-popup .inner-popup .inner-content .everything {
  margin-top: 30px;
  margin-bottom: 30px;
}

#nav-search-dynamic-popup .inner-popup .inner-content .everything a {
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 1199px) {
  #nav-search-dynamic-popup .inner-popup .inner-content .items .item img {
    width: 160px;
  }
}

@media (max-width: 991px) {
  #nav-search-dynamic-popup .inner-popup {
    width: 100%;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .items .item {
    flex: 0 1 auto;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .items .item label {
    padding: 0;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .items .item img {
    width: 120px;
  }
}

@media (max-width: 767px) {
  #nav-search-dynamic-popup .inner-popup .inner-content {
    padding: 15px;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .searched-for {
    margin-bottom: 10px;
    margin-right: 30px;
    margin-left: 30px;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .title {
    font-size: 20px;
    line-height: 1.25;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .title .br-mobile {
    display: block;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .subtitle {
    font-size: 15px;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .items {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .items .item {
    flex: 0 1 50%;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .items .item label {
    font-size: 15px;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .items .item img {
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .buttons a.orange-btn {
    padding: 10px 20px;
    margin-left: 0;
    display: block;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .everything {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #nav-search-dynamic-popup .inner-popup .inner-content .everything a {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  #nav-search-dynamic-popup {
    align-items: flex-start;
  }
}

#display-price-popup {
  display: none;
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1010;
}

#display-price-popup.active {
  display: flex;
}

#display-price-popup .overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#display-price-popup .inner-popup {
  width: 35%;
  display: block;
  z-index: 2;
  max-height: 100vh;
  overflow-y: auto;
  position: relative;
}

#display-price-popup .inner-popup .btn-close {
  display: block;
  font-size: 34px;
  padding: 5px;
  position: absolute;
  top: 0px;
  right: 12px;
  z-index: 2;
}

#display-price-popup .inner-popup .btn-close:hover {
  cursor: pointer;
  color: black;
}

#display-price-popup .inner-popup .inner-content {
  background-color: white;
  padding: 30px;
  position: relative;
}

#display-price-popup .inner-popup .inner-content .searched-for {
  margin-bottom: 2em;
}

#display-price-popup .inner-popup .inner-content .title {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}

#display-price-popup .inner-popup .inner-content .subtitle {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
}

#display-price-popup .inner-popup .inner-content a {
  border-bottom: 1px dotted #337ab7;
}

#display-price-popup .inner-popup .inner-content a:hover {
  text-decoration: none;
  border-bottom: 1px solid #22527b;
}

#display-price-popup .inner-popup .inner-content .items {
  display: flex;
  margin-top: 30px;
  margin-bottom: 0;
  flex-wrap: wrap;
  justify-content: center;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal {
  width: 100%;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group .label {
  /*display: block;
                            width: 100%;*/
  font-size: 14px;
  color: #000;
  text-align: left;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group .label.error, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group .label span {
  color: #a11c1c;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group input, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group select, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea {
  color: #000;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group input.error, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group select.error, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea.error {
  border-color: #a11c1c;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group input ::-webkit-input-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group select ::-webkit-input-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000 !important;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group input :-moz-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group select :-moz-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000 !important;
  opacity: 1;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group input ::-moz-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group select ::-moz-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000 !important;
  opacity: 1;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group input :-ms-input-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group select :-ms-input-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group input ::-ms-input-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group select ::-ms-input-placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000 !important;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group input ::placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group select ::placeholder, #display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea ::placeholder {
  /* Most modern browsers support this now. */
  color: #000 !important;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons {
  margin-bottom: 0;
  padding-top: 15px;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons a.orange-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 10px 55px;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
  font-weight: 600;
  white-space: normal;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons a.orange-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons a.orange-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#display-price-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons a.orange-btn:hover {
  background-color: #a5341c;
}

#display-price-popup .inner-popup .inner-content.selected .buttons a.orange-btn {
  padding: 10px 55px;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
}

#display-price-popup .inner-popup .inner-content.selected .buttons a.orange-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#display-price-popup .inner-popup .inner-content.selected .buttons a.orange-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#display-price-popup .inner-popup .inner-content.selected .buttons a.orange-btn:hover {
  background-color: #a5341c;
}

@media (max-width: 1199px) {
  #display-price-popup .inner-popup .inner-content .items .item img {
    width: 160px;
  }
}

@media (max-width: 991px) {
  #display-price-popup .inner-popup {
    width: 100%;
  }
  #display-price-popup .inner-popup .inner-content .items .item {
    flex: 0 1 auto;
  }
  #display-price-popup .inner-popup .inner-content .items .item label {
    padding: 0;
  }
  #display-price-popup .inner-popup .inner-content .items .item img {
    width: 120px;
  }
}

@media (max-width: 767px) {
  #display-price-popup .inner-popup .inner-content {
    padding: 15px;
  }
  #display-price-popup .inner-popup .inner-content .searched-for {
    margin-bottom: 10px;
    margin-right: 30px;
    margin-left: 30px;
  }
  #display-price-popup .inner-popup .inner-content .title {
    font-size: 20px;
    line-height: 1.25;
  }
  #display-price-popup .inner-popup .inner-content .title .br-mobile {
    display: block;
  }
  #display-price-popup .inner-popup .inner-content .subtitle {
    font-size: 15px;
  }
  #display-price-popup .inner-popup .inner-content .items {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  #display-price-popup .inner-popup .inner-content .items .item {
    flex: 0 1 50%;
  }
  #display-price-popup .inner-popup .inner-content .items .item label {
    font-size: 15px;
  }
  #display-price-popup .inner-popup .inner-content .items .item img {
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  #display-price-popup .inner-popup .inner-content .buttons a.orange-btn {
    padding: 10px 20px;
    margin-left: 0;
    display: block;
  }
  #display-price-popup .inner-popup .inner-content .everything {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #display-price-popup .inner-popup .inner-content .everything a {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  #display-price-popup {
    align-items: flex-start;
  }
}

body header {
  /*mobile versions*/
}

body header .nav-search.model, body header .nav-search.type {
  font-family: "Montserrat", sans-serif;
}

body header .nav-search.model .title, body header .nav-search.type .title {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
}

body header .nav-search.model .model-icons, body header .nav-search.model .type-icons, body header .nav-search.type .model-icons, body header .nav-search.type .type-icons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

body header .nav-search.model .model-icons .item, body header .nav-search.model .type-icons .item, body header .nav-search.type .model-icons .item, body header .nav-search.type .type-icons .item {
  display: block;
  flex: 0.25 0;
  text-align: center;
  margin-bottom: 15px;
}

body header .nav-search.model .model-icons .item .icon-data, body header .nav-search.model .type-icons .item .icon-data, body header .nav-search.type .model-icons .item .icon-data, body header .nav-search.type .type-icons .item .icon-data {
  margin-left: 5px;
  margin-right: 5px;
}

body header .nav-search.model .model-icons .item .model-image, body header .nav-search.model .type-icons .item .model-image, body header .nav-search.type .model-icons .item .model-image, body header .nav-search.type .type-icons .item .model-image {
  margin-bottom: 10px;
}

body header .nav-search.model .model-icons .item .type-image, body header .nav-search.model .type-icons .item .type-image, body header .nav-search.type .model-icons .item .type-image, body header .nav-search.type .type-icons .item .type-image {
  height: 75px;
}

body header .nav-search.model .model-icons .item a.model-label, body header .nav-search.model .model-icons .item a.type-label, body header .nav-search.model .type-icons .item a.model-label, body header .nav-search.model .type-icons .item a.type-label, body header .nav-search.type .model-icons .item a.model-label, body header .nav-search.type .model-icons .item a.type-label, body header .nav-search.type .type-icons .item a.model-label, body header .nav-search.type .type-icons .item a.type-label {
  color: #000;
  display: block;
  font-weight: 700;
}

body header .nav-search.model .model-icons .item .count, body header .nav-search.model .type-icons .item .count, body header .nav-search.type .model-icons .item .count, body header .nav-search.type .type-icons .item .count {
  font-weight: 600;
  color: #5b6268;
}

body header .nav-search.model .model-icons .item.btn-container, body header .nav-search.model .type-icons .item.btn-container, body header .nav-search.type .model-icons .item.btn-container, body header .nav-search.type .type-icons .item.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

body header .nav-search.model .model-icons .item.btn-container a.orange-btn, body header .nav-search.model .type-icons .item.btn-container a.orange-btn, body header .nav-search.type .model-icons .item.btn-container a.orange-btn, body header .nav-search.type .type-icons .item.btn-container a.orange-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  padding: 10px 55px;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 0px;
  padding: 8px 20px !important;
  white-space: normal;
}

body header .nav-search.model .model-icons .item.btn-container a.orange-btn span:after, body header .nav-search.model .type-icons .item.btn-container a.orange-btn span:after, body header .nav-search.type .model-icons .item.btn-container a.orange-btn span:after, body header .nav-search.type .type-icons .item.btn-container a.orange-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

body header .nav-search.model .model-icons .item.btn-container a.orange-btn span.open:after, body header .nav-search.model .type-icons .item.btn-container a.orange-btn span.open:after, body header .nav-search.type .model-icons .item.btn-container a.orange-btn span.open:after, body header .nav-search.type .type-icons .item.btn-container a.orange-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

body header .nav-search.model .model-icons .item.btn-container a.orange-btn:hover, body header .nav-search.model .type-icons .item.btn-container a.orange-btn:hover, body header .nav-search.type .model-icons .item.btn-container a.orange-btn:hover, body header .nav-search.type .type-icons .item.btn-container a.orange-btn:hover {
  background-color: #a5341c;
}

body header .nav-search.model .model-icons.max-5 .item, body header .nav-search.model .type-icons.max-5 .item, body header .nav-search.type .model-icons.max-5 .item, body header .nav-search.type .type-icons.max-5 .item {
  flex: 20% 0 0;
}

body header .nav-primary-mobile .nav-search.model .model-icons, body header .nav-primary-mobile .nav-search.model .type-icons, body header .nav-primary-mobile .nav-search.type .model-icons, body header .nav-primary-mobile .nav-search.type .type-icons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

body header .nav-primary-mobile .nav-search.model .model-icons .item, body header .nav-primary-mobile .nav-search.model .type-icons .item, body header .nav-primary-mobile .nav-search.type .model-icons .item, body header .nav-primary-mobile .nav-search.type .type-icons .item {
  flex: 1 1 auto;
  flex: 1 1 33.33%;
}

body header .nav-primary-mobile .nav-search.model .model-icons .btn-container, body header .nav-primary-mobile .nav-search.model .type-icons .btn-container, body header .nav-primary-mobile .nav-search.type .model-icons .btn-container, body header .nav-primary-mobile .nav-search.type .type-icons .btn-container {
  flex: 1 1 100%;
}

body header .nav-primary-mobile .nav-search.model .model-icons .item {
  flex: 1 1 auto;
  flex: 1 1 25%;
}

@media (max-width: 650px) {
  body header .nav-primary-mobile .nav-search.type .type-icons .item {
    flex: 1 1 50%;
  }
}

@media (max-width: 470px) {
  body header .nav-primary-mobile .nav-search.model .model-icons .item {
    flex: 1 1 50%;
  }
}

@media (max-width: 400px) {
  body header .nav-primary-mobile .nav-search.model .model-icons .item, body header .nav-primary-mobile .nav-search.model .type-icons .item, body header .nav-primary-mobile .nav-search.type .model-icons .item, body header .nav-primary-mobile .nav-search.type .type-icons .item {
    flex: 1 1 100%;
  }
}

.umb-grid .grid-section .horizontal-list-section {
  padding: 0 30px 30px 0;
}

.umb-grid .grid-section .horizontal-list-section .flex-item {
  padding: 15px;
  flex: 1 1 0;
  text-align: center;
}

.umb-grid .grid-section .horizontal-list-section .flex-item h2.title {
  font-size: 22px;
  font-weight: 800;
  color: #232323;
}

.umb-grid .grid-section .horizontal-list-section .flex-item .image {
  padding: 0 30px;
}

.umb-grid .grid-section .horizontal-list-section .flex-item .image img {
  width: 100%;
  max-width: 145px;
}

.umb-grid .grid-section .horizontal-list-section .flex-item.numbered-list {
  text-align: left;
}

.umb-grid .grid-section .horizontal-list-section .flex-item.numbered-list .flex-content {
  display: inline-block;
}

.umb-grid .grid-section .horizontal-list-section .flex-item.numbered-list .flex-row-n {
  display: flex;
}

.umb-grid .grid-section .horizontal-list-section .flex-item.numbered-list .flex-row-n .number {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: normal;
  flex-shrink: 0;
  margin-top: 20px;
  background-color: #e6762c;
  width: 60px;
  height: 60px;
  color: #fff;
  display: inline-block;
  border-radius: 30px;
  padding: 0px;
  height: 40px;
  width: 40px;
  position: relative;
  margin-right: 10px;
}

.umb-grid .grid-section .horizontal-list-section .flex-item.numbered-list .flex-row-n .number span {
  position: absolute;
  display: block;
  top: 0%;
  left: -0.02em;
  width: 100%;
  font-size: 22px;
  text-align: center;
  margin-top: 0.2em;
  font-weight: 500;
}

.umb-grid .grid-section .horizontal-list-section .flex-item.numbered-list .flex-row-n .number.number-1, .umb-grid .grid-section .horizontal-list-section .flex-item.numbered-list .flex-row-n .number.number-4 {
  background-color: #cd3412;
}

.umb-grid .grid-section .horizontal-list-section .flex-item.numbered-list .flex-row-n .number.number-3, .umb-grid .grid-section .horizontal-list-section .flex-item.numbered-list .flex-row-n .number.number-6 {
  background-color: #31728e;
}

@media (max-width: 767px) {
  .umb-grid .grid-section .horizontal-list-section .flex-item {
    flex: 1 1 100%;
  }
}

.umb-grid .grid-section .image-content-row {
  margin-top: 30px;
}

.umb-grid .grid-section .image-content-row .flex-col {
  flex: 1 0 0;
  padding: 0 15px 15px 15px;
}

.umb-grid .grid-section .image-content-row .flex-col .heading {
  margin-top: 0;
}

.umb-grid .grid-section .image-content-row .flex-col-image {
  flex: 0 1 auto;
}

.umb-grid .grid-section .image-content-row .flex-col-image img {
  max-width: 100%;
}

@media (max-width: 767px) {
  .umb-grid .grid-section .image-content-row .flex-col {
    flex: 1 1 100%;
  }
  .umb-grid .grid-section .image-content-row .flex-col-image {
    flex: 1 1 100%;
  }
}

.umb-grid .grid-section .faq-grid-container {
  margin: 0px 0;
  padding: 55px 15px 75px;
  width: 100%;
  background-color: #f2f2f2;
}

.umb-grid .grid-section .faq-grid-container h2 {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  color: #141719;
  font-weight: 600;
  margin: 0px 0 50px;
}

.umb-grid .grid-section .faq-grid-container h3 {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #232323;
  font-weight: 600;
  margin: 15px 0 15px;
  line-height: 1.4;
}

.umb-grid .grid-section .faq-grid-container .items .items-mobile {
  display: none;
}

.umb-grid .grid-section .faq-grid-container .items .items-desktop {
  display: flex;
  flex-wrap: wrap;
}

.umb-grid .grid-section .faq-grid-container .items .col1, .umb-grid .grid-section .faq-grid-container .items .col2 {
  flex: 0 0 50%;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item {
  /*display: inline-block;*/
  /* flex: 0 0 50%;*/
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content {
  margin-right: 30px;
  margin-left: 30px;
  border-top: 1px solid #fff;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content.first {
  border-top: 0;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content .icon {
  position: absolute;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content .icon::before {
  color: #30728d;
  content: "+";
  width: 1em;
  margin-right: 0px;
  font-size: 20px;
  font-weight: normal;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content .question {
  display: block;
  vertical-align: middle;
  position: relative;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content .question .title {
  margin-left: 20px;
  display: inline-block;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content .question .title:hover {
  text-decoration: underline;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content .question:hover {
  text-decoration: underline;
  cursor: pointer;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content .question:hover .icon::before {
  text-decoration: none;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content .answer {
  max-height: 1px;
  transition: max-height linear 0.2s;
  overflow: hidden;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content .answer .answer-content {
  padding-bottom: 15px;
}

.umb-grid .grid-section .faq-grid-container .items .faq-item.active .inner-content .icon::before {
  content: "-";
}

.umb-grid .grid-section .faq-grid-container .items .faq-item:first-child .inner-content {
  border-top: 0;
}

@media (max-width: 991px) {
  .umb-grid .grid-section .faq-grid-container .items .items-desktop {
    display: none;
  }
  .umb-grid .grid-section .faq-grid-container .items .items-mobile {
    display: flex;
    flex-wrap: wrap;
  }
  .umb-grid .grid-section .faq-grid-container .items .faq-item {
    flex: 1 1 100%;
  }
  .umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content, .umb-grid .grid-section .faq-grid-container .items .faq-item .inner-content.first {
    margin-left: 0;
    margin-right: 0;
    border-top: 1px solid #fff;
  }
  .umb-grid .grid-section .faq-grid-container .items .faq-item:first-child .inner-content {
    border-top: 0px;
  }
}

.umb-grid .grid-section .lending-partners-section {
  margin: 15px 0 0;
  padding: 50px 15px 75px;
}

.umb-grid .grid-section .lending-partners-section h2 {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  color: #000;
  font-weight: 600;
  margin: 0px 0 15px;
}

.umb-grid .grid-section .lending-partners-section .text {
  text-align: center;
  width: 100%;
  display: block;
  margin: 0px 0 30px;
}

.umb-grid .grid-section .lending-partners-section .row .col-md-12.lender-logos {
  width: 100%;
  text-align: center;
  padding-top: 5px;
}

.umb-grid .grid-section .lending-partners-section .row .col-md-12.lender-logos .lender-logo-item {
  display: inline-block;
  width: auto;
}

.umb-grid .grid-section .lending-partners-section .row .col-md-12.lender-logos .lender-logo-item img {
  padding: 15px 20px;
  max-width: 100%;
}

@media (max-width: 1440px) {
  .umb-grid .grid-section .lending-partners-section h2 {
    font-size: 28px;
  }
  .umb-grid .grid-section .lending-partners-section .row .col-md-12.lender-logos {
    display: flex;
    flex-wrap: wrap;
  }
  .umb-grid .grid-section .lending-partners-section .row .col-md-12.lender-logos .lender-logo-item {
    flex: 1 1 33.333%;
  }
}

@media (max-width: 767px) {
  .umb-grid .grid-section .lending-partners-section .row .col-md-12.lender-logos {
    display: flex;
    flex-wrap: wrap;
  }
  .umb-grid .grid-section .lending-partners-section .row .col-md-12.lender-logos .lender-logo-item {
    flex: 1 1 50%;
  }
}

.umb-grid .grid-section .button-grid-container {
  text-transform: uppercase;
}

.umb-grid .grid-section .button-grid-container a {
  margin-top: 15px;
  margin-bottom: 15px;
}

.umb-grid .grid-section .button-grid-container a.redorange-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 10px 55px;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
  font-weight: 600;
}

.umb-grid .grid-section .button-grid-container a.redorange-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

.umb-grid .grid-section .button-grid-container a.redorange-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

.umb-grid .grid-section .button-grid-container a.redorange-btn:hover {
  background-color: #a5341c;
}

.umb-grid .grid-section .button-grid-container a.orange-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 10px 55px;
  background-color: #e6762c;
  color: #fff;
  text-shadow: 0px 0px 5px #9a4812;
  font-weight: 600;
}

.umb-grid .grid-section .button-grid-container a.orange-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

.umb-grid .grid-section .button-grid-container a.orange-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

.umb-grid .grid-section .button-grid-container a.orange-btn:hover {
  background-color: #c75e18;
}

.umb-grid .grid-section .button-grid-container a.darkblue-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 10px 55px;
  background-color: #1e415f;
  color: #fff;
  text-shadow: 0px 0px 5px #060c11;
  font-weight: 600;
}

.umb-grid .grid-section .button-grid-container a.darkblue-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

.umb-grid .grid-section .button-grid-container a.darkblue-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

.umb-grid .grid-section .button-grid-container a.darkblue-btn:hover {
  background-color: #122638;
}

.umb-grid .grid-section .button-grid-container a.lightblue-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 10px 55px;
  background-color: #2e7d9c;
  color: #fff;
  text-shadow: 0px 0px 5px #173e4d;
  /*#3690b4*/
  font-weight: 600;
}

.umb-grid .grid-section .button-grid-container a.lightblue-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

.umb-grid .grid-section .button-grid-container a.lightblue-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

.umb-grid .grid-section .button-grid-container a.lightblue-btn:hover {
  background-color: #225d75;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item {
  font-size: 15px;
  line-height: 1.6;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px dotted #000;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item h3 {
  font-size: 18px;
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: 0;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .inner-content {
  font-size: 14px;
  line-height: 1.65;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .content-image img {
  width: 100%;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .subitem {
  position: relative;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .location .fa {
  width: 1.25em;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .testimonial {
  padding: 20px 0 0;
  /*@include fontStyle($roboto-font, $black, 16px);*/
  text-align: left;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .testimonial:before {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  /*--adjust as necessary--*/
  color: #404b55;
  font-size: 35px;
  padding-right: 0.5em;
  position: absolute;
  top: -5px;
  right: unset;
  bottom: unset;
  left: 0px;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .testimonial .testimonial-author {
  text-align: right;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  padding: 20px 0 0;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .testimonial .testimonials-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  padding: 10px 20px;
  background-color: #e6762c;
  color: #fff;
  text-shadow: 0px 0px 5px #9a4812;
  margin: 25px 0 0;
  display: block;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .testimonial .testimonials-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item .testimonial .testimonials-btn:hover {
  background-color: #c75e18;
}

.umb-grid .grid-section .sidebar-grid-container .sidebar-item:last-child {
  border-bottom: 0px;
  padding-bottom: 0;
}

.umb-grid .grid-section .sidebar-grid-container + .offers-slider-container {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px dotted #000;
}

.umb-grid .grid-section .multi-column-list-container ul {
  display: flex;
  flex-wrap: wrap;
}

.umb-grid .grid-section .multi-column-list-container ul li {
  flex: 0 1 33.333%;
  padding-right: 1em;
}

.umb-grid .grid-section .multi-column-list-container ul.small {
  font-size: 14px;
}

.umb-grid .grid-section .multi-column-list-container ul.span-1 li {
  flex-basis: 100%;
}

.umb-grid .grid-section .multi-column-list-container ul.span-2 li {
  flex-basis: 50%;
}

.umb-grid .grid-section .multi-column-list-container ul.span-3 li {
  flex-basis: 33.333%;
}

.umb-grid .grid-section .multi-column-list-container ul.span-4 li {
  flex-basis: 25%;
}

.umb-grid .grid-section .multi-column-list-container ul.span-5 li {
  flex-basis: 20%;
}

.umb-grid .grid-section .multi-column-list-container ul.span-6 li {
  flex-basis: 16.666%;
}

@media (max-width: 991px) {
  .umb-grid .grid-section .multi-column-list-container ul.span-5 li {
    flex-basis: 100%;
  }
  .umb-grid .grid-section .multi-column-list-container ul.span-6 li {
    flex-basis: 100%;
  }
}

@media (max-width: 767px) {
  .umb-grid .grid-section .multi-column-list-container ul.span-2 li {
    flex-basis: 100%;
  }
  .umb-grid .grid-section .multi-column-list-container ul.span-3 li {
    flex-basis: 100%;
  }
  .umb-grid .grid-section .multi-column-list-container ul.span-4 li {
    flex-basis: 100%;
  }
}

.payment-calculator.subpage main .hero-breadcrumb, .value-your-trade.subpage main .hero-breadcrumb {
  margin-bottom: 0;
}

.payment-calculator.subpage main .hero-breadcrumb .breadcrumbs ul, .value-your-trade.subpage main .hero-breadcrumb .breadcrumbs ul {
  margin-bottom: 0;
}

.payment-calculator.subpage main #payment-calculator-container, .value-your-trade.subpage main #payment-calculator-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #f1f1f1;
  padding: 25px;
}

.payment-calculator.subpage main #payment-calculator-container::after, .value-your-trade.subpage main #payment-calculator-container::after {
  display: block;
  content: "";
  clear: both;
}

.payment-calculator.subpage main #payment-calculator-container #loading-overlay, .value-your-trade.subpage main #payment-calculator-container #loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
}

.payment-calculator.subpage main #payment-calculator-container #loading-overlay:after, .value-your-trade.subpage main #payment-calculator-container #loading-overlay:after {
  /*content: "\f000";
                        font-family: FontAwesome;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: inherit;
                        color: #000;
                        font-size: 18px;
                        padding-right: 0.5em;
                        position: absolute;
                        top: 10px;
                        left: 0;*/
}

.payment-calculator.subpage main #payment-calculator-container .col-md-9, .value-your-trade.subpage main #payment-calculator-container .col-md-9 {
  margin-bottom: 10px;
}

.payment-calculator.subpage main #payment-calculator-container .form, .value-your-trade.subpage main #payment-calculator-container .form {
  padding-right: 10px;
}

.payment-calculator.subpage main #payment-calculator-container .form::after, .value-your-trade.subpage main #payment-calculator-container .form::after {
  display: block;
  content: "";
  clear: both;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6:nth-child(odd), .value-your-trade.subpage main #payment-calculator-container .form .col-md-6:nth-child(odd) {
  padding-left: 0;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6:nth-child(even), .value-your-trade.subpage main #payment-calculator-container .form .col-md-6:nth-child(even) {
  padding-left: 15px;
  padding-right: 0;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group label, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group label {
  display: block;
  width: 100%;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  color: #545454;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group label::after, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group label::after {
  display: block;
  content: "";
  clear: both;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group label a, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group label a {
  display: inline-block;
  float: right;
  font-size: 12px;
  border-bottom: dotted 1px;
  line-height: 16px;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group label a:hover, .payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group label a:active, .payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group label a:focus, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group label a:hover, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group label a:active, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group label a:focus {
  text-decoration: none;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group input, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group input {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #232323;
  font-weight: 600;
  border: solid 2px #cbcbcb;
  height: 40px;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group input.placeholder, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group input.placeholder {
  color: #232323;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group input:-moz-placeholder, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group input:-moz-placeholder {
  color: #232323;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group input::-moz-placeholder, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group input::-moz-placeholder {
  color: #232323;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group input:-ms-input-placeholder, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group input:-ms-input-placeholder {
  color: #232323;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group input::-webkit-input-placeholder, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group input::-webkit-input-placeholder {
  color: #232323;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group .input-group .input-group-addon, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group .input-group .input-group-addon {
  background-color: #cbcbcb;
  padding: 6px 8px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #5c6165;
  font-weight: 700;
}

.payment-calculator.subpage main #payment-calculator-container .form .col-md-6 .form-group select, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 .form-group select {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #232323;
  font-weight: 600;
  height: 40px;
  border: solid 2px #cbcbcb;
}

@media (max-width: 991px) {
  .payment-calculator.subpage main #payment-calculator-container .form .col-md-6, .value-your-trade.subpage main #payment-calculator-container .form .col-md-6 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.payment-calculator.subpage main #payment-calculator-container h2, .value-your-trade.subpage main #payment-calculator-container h2 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 24px;
}

.payment-calculator.subpage main #payment-calculator-container .payment-calculator-disclaimer, .value-your-trade.subpage main #payment-calculator-container .payment-calculator-disclaimer {
  font-size: 12px;
  padding: 10px;
  line-height: 18px;
}

@media (max-width: 991px) {
  .payment-calculator.subpage main #payment-calculator-container .payment-calculator-disclaimer, .value-your-trade.subpage main #payment-calculator-container .payment-calculator-disclaimer {
    margin-bottom: 10px;
  }
}

.payment-calculator.subpage main #payment-calculator-container .payment-display, .value-your-trade.subpage main #payment-calculator-container .payment-display {
  background-color: #30728d;
  border-radius: 5px;
  padding: 28px 0 20px;
}

.payment-calculator.subpage main #payment-calculator-container .payment-display span, .value-your-trade.subpage main #payment-calculator-container .payment-display span {
  display: block;
  text-align: center;
  width: 100%;
  display: block;
}

.payment-calculator.subpage main #payment-calculator-container .payment-display span:nth-child(odd), .value-your-trade.subpage main #payment-calculator-container .payment-display span:nth-child(odd) {
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  color: #fff;
  font-weight: 700;
  line-height: 34px;
}

.payment-calculator.subpage main #payment-calculator-container .payment-display span:nth-child(odd) span, .value-your-trade.subpage main #payment-calculator-container .payment-display span:nth-child(odd) span {
  font-size: 26px;
  display: contents;
}

.payment-calculator.subpage main #payment-calculator-container .payment-display span:nth-child(even), .value-your-trade.subpage main #payment-calculator-container .payment-display span:nth-child(even) {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  line-height: 24px;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info, .value-your-trade.subpage main #payment-calculator-container .payment-review-info {
  border-bottom: dotted 1px #3d4248;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info .review-pricing, .value-your-trade.subpage main #payment-calculator-container .payment-review-info .review-pricing {
  padding: 15px 0;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info .review-pricing span, .value-your-trade.subpage main #payment-calculator-container .payment-review-info .review-pricing span {
  display: block;
  text-align: left;
  width: 100%;
  display: block;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(odd), .value-your-trade.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(odd) {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #333d46;
  font-weight: 800;
  line-height: 24px;
  text-transform: uppercase;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even), .value-your-trade.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even) {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #141719;
  font-weight: 700;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even) a, .value-your-trade.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even) a {
  border-bottom: dotted 1px;
  line-height: 16px;
  font-weight: 500;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:hover, .payment-calculator.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:active, .payment-calculator.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:focus, .value-your-trade.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:hover, .value-your-trade.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:active, .value-your-trade.subpage main #payment-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:focus {
  text-decoration: none;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info table, .value-your-trade.subpage main #payment-calculator-container .payment-review-info table {
  width: 100%;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info table tr td, .value-your-trade.subpage main #payment-calculator-container .payment-review-info table tr td {
  padding: 2px 0;
  height: 36px;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info table tr td:nth-child(odd), .value-your-trade.subpage main #payment-calculator-container .payment-review-info table tr td:nth-child(odd) {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #232323;
  font-weight: 400;
  text-align: left;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info table tr td:nth-child(even), .value-your-trade.subpage main #payment-calculator-container .payment-review-info table tr td:nth-child(even) {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #232a30;
  font-weight: 500;
  text-align: right;
  line-height: 32px;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info table tr td:nth-child(even) span, .value-your-trade.subpage main #payment-calculator-container .payment-review-info table tr td:nth-child(even) span {
  display: inline-block;
  margin-right: 10px;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info table tr td.finance-amount, .value-your-trade.subpage main #payment-calculator-container .payment-review-info table tr td.finance-amount {
  color: #ce301a;
  font-weight: 800;
}

.payment-calculator.subpage main #payment-calculator-container .payment-review-info table tr td.sale-price-amount, .value-your-trade.subpage main #payment-calculator-container .payment-review-info table tr td.sale-price-amount {
  color: #1e4668;
  font-weight: 600;
}

.payment-calculator.subpage main #payment-calculator-container .pre-approve-btn, .value-your-trade.subpage main #payment-calculator-container .pre-approve-btn {
  padding: 15px 0;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}

.payment-calculator.subpage main #payment-calculator-container .pre-approve-btn span:after, .value-your-trade.subpage main #payment-calculator-container .pre-approve-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.payment-calculator.subpage main #payment-calculator-container .pre-approve-btn:hover, .value-your-trade.subpage main #payment-calculator-container .pre-approve-btn:hover {
  background-color: #a5341c;
}

.options-print-view {
  display: none;
}

@media print {
  .options-print-view {
    display: block;
  }
}

.options-print-view .form {
  margin-bottom: 20px;
}

.options-print-view table {
  width: 100%;
}

.options-print-view table tr td {
  vertical-align: top;
  padding: 0 10px 20px;
}

.options-print-view .form-section {
  padding-bottom: 15px;
}

.options-print-view .form-section::after {
  display: block;
  content: "";
  clear: both;
}

.options-print-view .option-heading {
  font-weight: 700;
}

.options-print-view .form-group {
  margin: 0 !important;
  padding: 0 !important;
}

.options-print-view .form-group .radio, .options-print-view .form-group .checkbox {
  margin: 0 !important;
  padding: 0 !important;
}

.options-print-view.tradein-result-section .tradein-result-price {
  width: 146px;
  margin: 0 auto;
  border: solid 1px #c2c2c2;
  padding: 1px;
  background-color: #fff;
}

.options-print-view.tradein-result-section .tradein-result-price div {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 100%;
  display: block;
  padding: 5px 0 0;
}

.options-print-view.tradein-result-section .tradein-result-price div span {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  width: 100%;
  display: block;
  display: block;
  line-height: 12px;
}

.options-print-view.tradein-result-section .tradein-result-price div.top {
  background-color: #30728d;
}

.options-print-view.tradein-result-section .tradein-result-price div.bottom {
  background-color: #fff;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #232323;
  font-weight: 600;
}

.options-print-view.tradein-result-section .tradein-result-price div.bottom span {
  color: #232323;
}

.options-print-view.tradein-result-section .tradein-result-image {
  text-align: center;
}

.options-print-view.tradein-result-section .tradein-value {
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  color: #ce301a;
  font-weight: 600;
  text-align: right;
  width: 100%;
  line-height: 50px;
}

.options-print-view.tradein-result-section .tradein-value span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #232323;
  font-weight: 400;
  text-align: right;
  width: 100%;
  display: block;
  line-height: 18px;
}

.options-print-view.tradein-result-section .tradein-buttons {
  text-align: right;
  padding: 5px 0;
}

.options-print-view.tradein-result-section .tradein-buttons .calculator-btn {
  padding: 10px 10px;
  background-color: none;
  color: #fff;
  border: solid 2px #2f728c;
  color: #2f728c;
  font-size: 18px;
}

.options-print-view.tradein-result-section .tradein-buttons .calculator-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.options-print-view.tradein-result-section .tradein-buttons .calculator-btn:hover {
  color: #f2f2f2;
  background-color: #2f728c;
}

.options-print-view.tradein-result-section .tradein-buttons .print-btn {
  padding: 10px 20px;
  background-color: #2e7d9c;
  color: #fff;
  text-shadow: 0px 0px 5px #173e4d;
  /*#3690b4*/
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  width: 180px;
}

.options-print-view.tradein-result-section .tradein-buttons .print-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.options-print-view.tradein-result-section .tradein-buttons .print-btn:hover {
  background-color: #225d75;
}

.options-print-view.tradein-result-section .tradein-buttons .request-info-btn {
  padding: 10px 20px;
  background-color: #1e415f;
  color: #fff;
  text-shadow: 0px 0px 5px #060c11;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  width: 180px;
}

.options-print-view.tradein-result-section .tradein-buttons .request-info-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

.options-print-view.tradein-result-section .tradein-buttons .request-info-btn:hover {
  background-color: #122638;
}

@media (max-width: 991px) {
  .options-print-view.tradein-result-section .tradein-buttons .calculator-btn, .options-print-view.tradein-result-section .tradein-buttons .request-info-btn, .options-print-view.tradein-result-section .tradein-buttons print-btn {
    width: 100%;
  }
}

.options-print-view.tradein-result-section .contact-info {
  text-align: right;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  padding-top: 10px;
}

.options-print-view.tradein-result-section .contact-info a {
  font-weight: 700;
  color: #000;
}

@media (max-width: 991px) {
  .options-print-view.tradein-result-section .contact-info {
    text-align: center;
  }
}

.powered-by {
  float: right;
}

#tradein-calculator-container, #edmunds-tradein-calculator-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}

#tradein-calculator-container::after, #edmunds-tradein-calculator-container::after {
  display: block;
  content: "";
  clear: both;
}

#tradein-calculator-container .errors, #edmunds-tradein-calculator-container .errors {
  background-color: #a11c1c;
  color: #fff;
  padding: 10px 15px;
}

#tradein-calculator-container .errors ul, #edmunds-tradein-calculator-container .errors ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#tradein-calculator-container .errors ul li, #edmunds-tradein-calculator-container .errors ul li {
  margin: 0;
  padding: 0;
  text-align: left;
}

#tradein-calculator-container .tradein-forms-container, #edmunds-tradein-calculator-container .tradein-forms-container {
  background-color: #f1f1f1;
  padding: 25px;
}

#tradein-calculator-container .tradein-forms-container::after, #edmunds-tradein-calculator-container .tradein-forms-container::after {
  display: block;
  content: "";
  clear: both;
}

#tradein-calculator-container .tradein-forms-container p, #edmunds-tradein-calculator-container .tradein-forms-container p {
  width: 100%;
  text-align: center;
}

#tradein-calculator-container .tradein-steps, #edmunds-tradein-calculator-container .tradein-steps {
  text-align: center;
  margin: 30px 0 86px;
}

#tradein-calculator-container .tradein-steps #tradein-progress, #edmunds-tradein-calculator-container .tradein-steps #tradein-progress {
  position: relative;
  height: 12px;
  max-width: 450px;
  margin: 0 auto;
  background-color: #b1b1b1;
}

@media (max-width: 661px) {
  #tradein-calculator-container .tradein-steps #tradein-progress, #edmunds-tradein-calculator-container .tradein-steps #tradein-progress {
    max-width: unset;
    width: 80%;
  }
}

#tradein-calculator-container .tradein-steps #tradein-progress-blue, #edmunds-tradein-calculator-container .tradein-steps #tradein-progress-blue {
  position: absolute;
  height: 100%;
  background-color: #2789b6;
  width: 0;
}

#tradein-calculator-container .tradein-steps #tradein-progress-blue.step2, #edmunds-tradein-calculator-container .tradein-steps #tradein-progress-blue.step2 {
  width: 50%;
}

#tradein-calculator-container .tradein-steps #tradein-progress-blue.step3, #edmunds-tradein-calculator-container .tradein-steps #tradein-progress-blue.step3 {
  width: 100%;
}

#tradein-calculator-container .tradein-steps .step-label, #edmunds-tradein-calculator-container .tradein-steps .step-label {
  height: 45px;
  width: 45px;
  background-color: #b1b1b1;
  border-radius: 50%;
  position: absolute;
  z-index: 555;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  color: #fff;
  font-weight: 500;
  padding-top: 5px;
  top: -20px;
  cursor: pointer;
}

#tradein-calculator-container .tradein-steps .step-label.active, #edmunds-tradein-calculator-container .tradein-steps .step-label.active {
  background-color: #173349;
}

#tradein-calculator-container .tradein-steps .step-label.label-1, #edmunds-tradein-calculator-container .tradein-steps .step-label.label-1 {
  left: -20px;
}

#tradein-calculator-container .tradein-steps .step-label.label-2, #edmunds-tradein-calculator-container .tradein-steps .step-label.label-2 {
  left: calc(50% - 20px);
}

#tradein-calculator-container .tradein-steps .step-label.label-3, #edmunds-tradein-calculator-container .tradein-steps .step-label.label-3 {
  right: -20px;
}

#tradein-calculator-container .tradein-steps .step-label span, #edmunds-tradein-calculator-container .tradein-steps .step-label span {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #232323;
  font-weight: 800;
  display: block;
  text-align: center;
  width: 100px;
  margin-left: -27px;
  margin-top: 12px;
}

#tradein-calculator-container h2, #edmunds-tradein-calculator-container h2 {
  text-align: center;
  width: 100%;
  display: block;
}

#tradein-calculator-container .col-md-9, #edmunds-tradein-calculator-container .col-md-9 {
  margin-bottom: 10px;
}

#tradein-calculator-container .form, #edmunds-tradein-calculator-container .form {
  padding-right: 10px;
}

#tradein-calculator-container .form::after, #edmunds-tradein-calculator-container .form::after {
  display: block;
  content: "";
  clear: both;
}

#tradein-calculator-container .form.options-form .form-section, #edmunds-tradein-calculator-container .form.options-form .form-section {
  padding-top: 10px;
}

#tradein-calculator-container .form button, #edmunds-tradein-calculator-container .form button {
  padding: 14px 85px;
  background-color: #e6762c;
  color: #fff;
  text-shadow: 0px 0px 5px #9a4812;
  text-transform: uppercase;
}

#tradein-calculator-container .form button span:after, #edmunds-tradein-calculator-container .form button span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

#tradein-calculator-container .form button:hover, #edmunds-tradein-calculator-container .form button:hover {
  background-color: #c75e18;
}

#tradein-calculator-container .form .form-section-wrapper, #edmunds-tradein-calculator-container .form .form-section-wrapper {
  width: 100%;
}

#tradein-calculator-container .form .form-section-wrapper.border-bottom, #edmunds-tradein-calculator-container .form .form-section-wrapper.border-bottom {
  border-bottom: solid 1px #ccc;
}

#tradein-calculator-container .form .form-section-wrapper .form-section, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section {
  margin: 0 auto;
}

#tradein-calculator-container .form .form-section-wrapper .form-section::after, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section::after {
  display: block;
  content: "";
  clear: both;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.wide, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.wide {
  width: 100%;
  max-width: unset;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .radio-option-list label, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .radio-option-list label {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #232323;
  font-weight: 400;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .option-selector-group label, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .option-selector-group label {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #232323;
  font-weight: 400;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .option-selector-group label span, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .option-selector-group label span {
  display: inline-block;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .option-selector-group label span span, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .option-selector-group label span span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #232323;
  font-weight: 400;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .option-selector-group .option-separator, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .option-selector-group .option-separator {
  font-weight: 600;
  padding-top: 10px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .color-group, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .color-group {
  margin: 0 auto;
  padding-bottom: 20px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .color-group::after, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .color-group::after {
  display: block;
  content: "";
  clear: both;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6 {
  padding: 0 0 0 70px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6 .form-group, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6 .form-group {
  margin: 0;
  padding: 0;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6 .form-group .radio, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6 .form-group .radio {
  margin: 0;
  padding-bottom: 5px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6 .form-group .radio label, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6 .form-group .radio label {
  font-size: 15px;
}

@media (max-width: 991px) {
  #tradein-calculator-container .form .form-section-wrapper .form-section .color-group, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .color-group {
    max-width: 100%;
  }
  #tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section .color-group .col-md-6 {
    margin-right: 20px;
  }
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .condition-disclaimer, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .condition-disclaimer {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 40px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group {
  margin-bottom: 12px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label {
  display: block;
  width: 100%;
  height: 186px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 2px #919191;
  padding: 0 !important;
  margin: 0 !important;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:before, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:before {
  display: none !important;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label .condition-heading, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label .condition-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  color: #232323;
  font-weight: 600;
  text-align: center;
  width: 100%;
  display: block;
  width: 90%;
  margin: 0 auto;
  padding-top: 32px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label .condition-percent, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label .condition-percent {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  color: #2b5c85;
  font-weight: 700;
  text-align: center;
  width: 100%;
  display: block;
  width: 90%;
  margin: 0 auto;
  padding-top: 12px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label .condition-description, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label .condition-description {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #232323;
  font-weight: 400;
  text-align: center;
  width: 100%;
  display: block;
  width: 85%;
  margin: 0 auto;
  padding-top: 5px;
  line-height: 20px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio]:checked ~ label, #tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:hover, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio]:checked ~ label, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:hover {
  background-color: #1e4668;
  border-color: #2389b3;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio]:checked ~ label .condition-heading, #tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:hover .condition-heading, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio]:checked ~ label .condition-heading, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:hover .condition-heading {
  color: #fff;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio]:checked ~ label .condition-percent, #tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:hover .condition-percent, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio]:checked ~ label .condition-percent, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:hover .condition-percent {
  color: #ff8a3c;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio]:checked ~ label .condition-description, #tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:hover .condition-description, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio]:checked ~ label .condition-description, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.condition-section .form-group .radio input[type=radio] ~ label:hover .condition-description {
  color: #fff;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price {
  width: 146px;
  margin: 0 auto;
  border: solid 1px #c2c2c2;
  padding: 1px;
  background-color: #fff;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 100%;
  display: block;
  padding: 5px 0 0;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div span, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div span {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  width: 100%;
  display: block;
  display: block;
  line-height: 12px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div.top, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div.top {
  background-color: #30728d;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div.bottom, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div.bottom {
  background-color: #fff;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #232323;
  font-weight: 600;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div.bottom span, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-price div.bottom span {
  color: #232323;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-image, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-image {
  text-align: center;
}

@media (max-width: 500px) {
  #tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-image img, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-result-image img {
    width: 100%;
  }
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-value, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-value {
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  color: #ce301a;
  font-weight: 600;
  text-align: right;
  width: 100%;
  line-height: 50px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-value span, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-value span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #232323;
  font-weight: 400;
  text-align: right;
  width: 100%;
  display: block;
  line-height: 18px;
}

@media (max-width: 991px) {
  #tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-value, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-value {
    padding: 20px 0 !important;
    text-align: center !important;
  }
  #tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-value span, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-value span {
    text-align: center !important;
  }
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons {
  text-align: right;
  padding: 5px 0;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .calculator-btn, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .calculator-btn {
  padding: 10px 10px;
  background-color: none;
  color: #fff;
  border: solid 2px #2f728c;
  color: #2f728c;
  font-size: 18px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .calculator-btn span:after, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .calculator-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .calculator-btn:hover, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .calculator-btn:hover {
  color: #f2f2f2;
  background-color: #2f728c;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .print-btn, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .print-btn {
  padding: 10px 20px;
  background-color: #2e7d9c;
  color: #fff;
  text-shadow: 0px 0px 5px #173e4d;
  /*#3690b4*/
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  width: 180px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .print-btn span:after, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .print-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .print-btn:hover, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .print-btn:hover {
  background-color: #225d75;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .request-info-btn, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .request-info-btn {
  padding: 10px 20px;
  background-color: #1e415f;
  color: #fff;
  text-shadow: 0px 0px 5px #060c11;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  width: 180px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .request-info-btn span:after, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .request-info-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .request-info-btn:hover, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .request-info-btn:hover {
  background-color: #122638;
}

@media (max-width: 991px) {
  #tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .calculator-btn, #tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .print-btn, #tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .request-info-btn, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .calculator-btn, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .print-btn, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .tradein-buttons .request-info-btn {
    width: 100%;
  }
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .contact-info, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .contact-info {
  text-align: right;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  padding-top: 10px;
}

#tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .contact-info a, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .contact-info a {
  font-weight: 700;
  color: #000;
}

@media (max-width: 991px) {
  #tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .contact-info, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .contact-info {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 485px) {
  #tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .contact-info a, #edmunds-tradein-calculator-container .form .form-section-wrapper .form-section.tradein-result-section .contact-info a {
    display: block;
  }
}

#tradein-calculator-container .form .form-section-wrapper .option-heading, #edmunds-tradein-calculator-container .form .form-section-wrapper .option-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #232323;
  font-weight: 800;
}

#tradein-calculator-container .form .form-section-wrapper h3, #edmunds-tradein-calculator-container .form .form-section-wrapper h3 {
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #333d46;
  font-weight: 700;
  margin-top: 40px;
}

#tradein-calculator-container .form .form-section-wrapper:first-child, #edmunds-tradein-calculator-container .form .form-section-wrapper:first-child {
  border-bottom: solid 1px #ccc;
  margin-bottom: 24px;
}

#tradein-calculator-container .form .trade-in-vehicle, #edmunds-tradein-calculator-container .form .trade-in-vehicle {
  width: 100%;
  background-color: #1e4668;
  color: #fff;
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 14px 0;
  font-size: 15px;
}

#tradein-calculator-container .form .trade-in-vehicle span, #edmunds-tradein-calculator-container .form .trade-in-vehicle span {
  display: block;
  width: 100%;
  text-align: center;
}

#tradein-calculator-container .form .trade-in-vehicle span:first-child, #edmunds-tradein-calculator-container .form .trade-in-vehicle span:first-child {
  font-weight: 700;
}

#tradein-calculator-container .form .col-md-6:nth-child(odd), #edmunds-tradein-calculator-container .form .col-md-6:nth-child(odd) {
  padding-left: 0;
}

#tradein-calculator-container .form .col-md-6:nth-child(even), #edmunds-tradein-calculator-container .form .col-md-6:nth-child(even) {
  padding-left: 15px;
  padding-right: 0;
}

#tradein-calculator-container .form .col-md-6.alt-padding:nth-child(odd), #edmunds-tradein-calculator-container .form .col-md-6.alt-padding:nth-child(odd) {
  padding-left: 0 !important;
  padding-right: 15px !important;
}

#tradein-calculator-container .form .col-md-6.alt-padding:nth-child(even), #edmunds-tradein-calculator-container .form .col-md-6.alt-padding:nth-child(even) {
  padding-right: 0 !important;
  padding-left: 15px !important;
}

@media (max-width: 991px) {
  #tradein-calculator-container .form .col-md-6.alt-padding:nth-child(odd), #edmunds-tradein-calculator-container .form .col-md-6.alt-padding:nth-child(odd) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #tradein-calculator-container .form .col-md-6.alt-padding:nth-child(even), #edmunds-tradein-calculator-container .form .col-md-6.alt-padding:nth-child(even) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (max-width: 991px) {
  #tradein-calculator-container .form .col-md-6, #edmunds-tradein-calculator-container .form .col-md-6 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

#tradein-calculator-container .form .col-md-12, #edmunds-tradein-calculator-container .form .col-md-12 {
  padding: 0;
}

#tradein-calculator-container .form .col-md-12.form-btn-col, #edmunds-tradein-calculator-container .form .col-md-12.form-btn-col {
  text-align: center;
}

#tradein-calculator-container .form .form-group, #edmunds-tradein-calculator-container .form .form-group {
  margin-bottom: 24px;
}

#tradein-calculator-container .form .form-group label, #edmunds-tradein-calculator-container .form .form-group label {
  display: block;
  width: 100%;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}

#tradein-calculator-container .form .form-group label::after, #edmunds-tradein-calculator-container .form .form-group label::after {
  display: block;
  content: "";
  clear: both;
}

#tradein-calculator-container .form .form-group label a, #edmunds-tradein-calculator-container .form .form-group label a {
  display: inline-block;
  float: right;
  font-size: 12px;
  border-bottom: dotted 1px;
  line-height: 16px;
}

#tradein-calculator-container .form .form-group label a:hover, #tradein-calculator-container .form .form-group label a:active, #tradein-calculator-container .form .form-group label a:focus, #edmunds-tradein-calculator-container .form .form-group label a:hover, #edmunds-tradein-calculator-container .form .form-group label a:active, #edmunds-tradein-calculator-container .form .form-group label a:focus {
  text-decoration: none;
}

#tradein-calculator-container .form .form-group input, #edmunds-tradein-calculator-container .form .form-group input {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #232323;
  font-weight: 500;
  border: solid 2px #cbcbcb;
  height: 40px;
}

#tradein-calculator-container .form .form-group input.placeholder, #edmunds-tradein-calculator-container .form .form-group input.placeholder {
  color: #232323;
}

#tradein-calculator-container .form .form-group input:-moz-placeholder, #edmunds-tradein-calculator-container .form .form-group input:-moz-placeholder {
  color: #232323;
}

#tradein-calculator-container .form .form-group input::-moz-placeholder, #edmunds-tradein-calculator-container .form .form-group input::-moz-placeholder {
  color: #232323;
}

#tradein-calculator-container .form .form-group input:-ms-input-placeholder, #edmunds-tradein-calculator-container .form .form-group input:-ms-input-placeholder {
  color: #232323;
}

#tradein-calculator-container .form .form-group input::-webkit-input-placeholder, #edmunds-tradein-calculator-container .form .form-group input::-webkit-input-placeholder {
  color: #232323;
}

#tradein-calculator-container .form .form-group .input-group .input-group-addon, #edmunds-tradein-calculator-container .form .form-group .input-group .input-group-addon {
  background-color: #cbcbcb;
  padding: 6px 8px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #5c6165;
  font-weight: 700;
}

#tradein-calculator-container .form .form-group .select-label, #edmunds-tradein-calculator-container .form .form-group .select-label {
  position: relative;
}

#tradein-calculator-container .form .form-group .select-label:after, #edmunds-tradein-calculator-container .form .form-group .select-label:after {
  content: "\f078";
  font-family: FontAwesome;
  /*--adjust as necessary--*/
  color: #232323;
  padding: 12px 8px;
  position: absolute;
  right: 7px;
  top: 22px;
  z-index: 1;
  font-size: 12px;
  text-align: center;
  width: 35px;
  height: 100%;
  pointer-events: none;
  box-sizing: border-box;
}

#tradein-calculator-container .form .form-group select, #edmunds-tradein-calculator-container .form .form-group select {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #232323;
  font-weight: 500;
  height: 40px;
  border: solid 2px #cbcbcb;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

#tradein-calculator-container .form .form-group select::-ms-expand, #edmunds-tradein-calculator-container .form .form-group select::-ms-expand {
  display: none;
}

#tradein-calculator-container .form .form-group select, #tradein-calculator-container .form .form-group input[type=text], #tradein-calculator-container .form .form-group input[type=number], #edmunds-tradein-calculator-container .form .form-group select, #edmunds-tradein-calculator-container .form .form-group input[type=text], #edmunds-tradein-calculator-container .form .form-group input[type=number] {
  height: 46px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #232323;
  font-weight: 500;
}

#tradein-calculator-container .form .form-group input[type=checkbox], #tradein-calculator-container .form .form-group input[type=radio], #edmunds-tradein-calculator-container .form .form-group input[type=checkbox], #edmunds-tradein-calculator-container .form .form-group input[type=radio] {
  height: auto;
  opacity: 0;
  height: 0;
  width: 0;
}

#tradein-calculator-container .form .form-group input[type=radio], #edmunds-tradein-calculator-container .form .form-group input[type=radio] {
  opacity: 5;
}

#tradein-calculator-container .form .form-group input[type=radio] ~ label:before, #edmunds-tradein-calculator-container .form .form-group input[type=radio] ~ label:before {
  content: ' ';
  position: absolute;
  width: 12px;
  height: 12px;
  background-image: url("/dist/img/radio-not-checked.png");
  background-repeat: no-repeat;
  top: 3px;
  left: 0;
}

#tradein-calculator-container .form .form-group input[type=radio] ~ label span.color-swatch, #edmunds-tradein-calculator-container .form .form-group input[type=radio] ~ label span.color-swatch {
  position: absolute;
  top: 3px;
  width: 12px;
  height: 12px;
}

#tradein-calculator-container .form .form-group input[type=radio] ~ label span.label-text, #edmunds-tradein-calculator-container .form .form-group input[type=radio] ~ label span.label-text {
  display: inline-block;
  padding-left: 20px;
}

#tradein-calculator-container .form .form-group input[type=radio]:checked ~ label:before, #edmunds-tradein-calculator-container .form .form-group input[type=radio]:checked ~ label:before {
  background-image: url("/dist/img/radio-checked.png");
}

#tradein-calculator-container .form .form-group input[type=checkbox] ~ label:before, #edmunds-tradein-calculator-container .form .form-group input[type=checkbox] ~ label:before {
  content: ' ';
  background-image: url("/dist/img/checkbox-not-checked.png");
  position: absolute;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  top: 3px;
  left: 0;
}

#tradein-calculator-container .form .form-group input[type=checkbox]:active ~ label:before, #tradein-calculator-container .form .form-group input[type=checkbox]:checked ~ label:before, #edmunds-tradein-calculator-container .form .form-group input[type=checkbox]:active ~ label:before, #edmunds-tradein-calculator-container .form .form-group input[type=checkbox]:checked ~ label:before {
  background-image: url("/dist/img/checkbox-checked.png");
}

#tradein-calculator-container .form .form-group.vin-group::after, #edmunds-tradein-calculator-container .form .form-group.vin-group::after {
  display: block;
  content: "";
  clear: both;
}

#tradein-calculator-container .form .form-group.vin-group label, #edmunds-tradein-calculator-container .form .form-group.vin-group label {
  display: inline-block;
  width: auto;
  padding-top: 15px;
  font-size: 15px;
}

#tradein-calculator-container .form .form-group.vin-group input, #edmunds-tradein-calculator-container .form .form-group.vin-group input {
  display: inline-block;
  width: auto;
  min-width: 76%;
  float: right;
}

@media (max-width: 598px) {
  #tradein-calculator-container .form .form-group.vin-group label, #edmunds-tradein-calculator-container .form .form-group.vin-group label {
    display: block;
  }
  #tradein-calculator-container .form .form-group.vin-group input, #edmunds-tradein-calculator-container .form .form-group.vin-group input {
    display: block;
    width: 100%;
  }
}

#tradein-calculator-container h2, #edmunds-tradein-calculator-container h2 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 24px;
}

#tradein-calculator-container .payment-calculator-disclaimer, #edmunds-tradein-calculator-container .payment-calculator-disclaimer {
  font-size: 12px;
  padding: 10px;
}

@media (max-width: 991px) {
  #tradein-calculator-container .payment-calculator-disclaimer, #edmunds-tradein-calculator-container .payment-calculator-disclaimer {
    margin-bottom: 10px;
  }
}

#tradein-calculator-container .payment-display, #edmunds-tradein-calculator-container .payment-display {
  background-color: #30728d;
  border-radius: 5px;
  padding: 28px 0 20px;
}

#tradein-calculator-container .payment-display span, #edmunds-tradein-calculator-container .payment-display span {
  display: block;
  text-align: center;
  width: 100%;
  display: block;
}

#tradein-calculator-container .payment-display span:nth-child(odd), #edmunds-tradein-calculator-container .payment-display span:nth-child(odd) {
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  color: #fff;
  font-weight: 700;
  line-height: 34px;
}

#tradein-calculator-container .payment-display span:nth-child(odd) span, #edmunds-tradein-calculator-container .payment-display span:nth-child(odd) span {
  font-size: 26px;
  display: contents;
}

#tradein-calculator-container .payment-display span:nth-child(even), #edmunds-tradein-calculator-container .payment-display span:nth-child(even) {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  line-height: 24px;
}

#tradein-calculator-container .payment-review-info, #edmunds-tradein-calculator-container .payment-review-info {
  border-bottom: dotted 1px #3d4248;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

#tradein-calculator-container .payment-review-info .review-pricing, #edmunds-tradein-calculator-container .payment-review-info .review-pricing {
  padding: 15px 0;
}

#tradein-calculator-container .payment-review-info .review-pricing span, #edmunds-tradein-calculator-container .payment-review-info .review-pricing span {
  display: block;
  text-align: left;
  width: 100%;
  display: block;
}

#tradein-calculator-container .payment-review-info .review-pricing span:nth-child(odd), #edmunds-tradein-calculator-container .payment-review-info .review-pricing span:nth-child(odd) {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #333d46;
  font-weight: 600;
  line-height: 24px;
}

#tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even), #edmunds-tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even) {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #141719;
  font-weight: 700;
}

#tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even) a, #edmunds-tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even) a {
  border-bottom: dotted 1px;
  line-height: 16px;
}

#tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:hover, #tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:active, #tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:focus, #edmunds-tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:hover, #edmunds-tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:active, #edmunds-tradein-calculator-container .payment-review-info .review-pricing span:nth-child(even) a:focus {
  text-decoration: none;
}

#tradein-calculator-container .payment-review-info table, #edmunds-tradein-calculator-container .payment-review-info table {
  width: 100%;
}

#tradein-calculator-container .payment-review-info table tr td, #edmunds-tradein-calculator-container .payment-review-info table tr td {
  padding: 2px 0;
}

#tradein-calculator-container .payment-review-info table tr td:nth-child(odd), #edmunds-tradein-calculator-container .payment-review-info table tr td:nth-child(odd) {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #232323;
  font-weight: 400;
  text-align: left;
}

#tradein-calculator-container .payment-review-info table tr td:nth-child(even), #edmunds-tradein-calculator-container .payment-review-info table tr td:nth-child(even) {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #232a30;
  font-weight: 500;
  text-align: right;
}

#tradein-calculator-container .payment-review-info table tr td:nth-child(even) span, #edmunds-tradein-calculator-container .payment-review-info table tr td:nth-child(even) span {
  display: inline-block;
  margin-right: 10px;
}

#tradein-calculator-container .payment-review-info table tr td.finance-amount, #edmunds-tradein-calculator-container .payment-review-info table tr td.finance-amount {
  color: #ce301a;
  font-weight: 800;
}

#tradein-calculator-container .payment-review-info table tr td.sale-price-amount, #edmunds-tradein-calculator-container .payment-review-info table tr td.sale-price-amount {
  color: #1e4668;
  font-weight: 600;
}

#tradein-calculator-container .pre-approve-btn, #edmunds-tradein-calculator-container .pre-approve-btn {
  padding: 15px 0;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}

#tradein-calculator-container .pre-approve-btn span:after, #edmunds-tradein-calculator-container .pre-approve-btn span:after {
  font-family: unset;
  content: "";
  color: #fff;
}

#tradein-calculator-container .pre-approve-btn:hover, #edmunds-tradein-calculator-container .pre-approve-btn:hover {
  background-color: #a5341c;
}

#tradepending-container {
  height: 700px !important;
}

.grid-section .col-md-3 .widget, .grid-section .col-md-4 .widget {
  margin: 0 0 20px;
  padding-bottom: 20px;
  border-bottom: dotted 1px #000;
}

.grid-section .col-md-3 .widget .heading, .grid-section .col-md-4 .widget .heading {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.grid-section .col-md-3 .contact-info-widget .info-span, .grid-section .col-md-4 .contact-info-widget .info-span {
  display: block;
  font-size: 15px;
  line-height: 24px;
}

.grid-section .col-md-3 .content-review-widget .review-item, .grid-section .col-md-4 .content-review-widget .review-item {
  padding: 0;
}

.grid-section .col-md-3 .content-review-widget .review-quote, .grid-section .col-md-4 .content-review-widget .review-quote {
  padding: 0 0 10px;
  display: block;
  float: none;
  /*@media(max-width: 991px){
                    margin-bottom: 10px;
                }*/
}

.grid-section .col-md-3 .content-review-widget .review-content, .grid-section .col-md-4 .content-review-widget .review-content {
  float: none;
  display: block;
}

.content-review-widget .review-item .review-heading {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.content-review-widget .review-item .review-quote img {
  width: 100%;
  max-width: 30px;
  min-width: 30px;
}

@media (max-width: 991px) {
  .content-review-widget .review-item .review-quote {
    margin-bottom: 7px;
  }
}

.content-review-widget .review-item .review-author {
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  /*font-weight: 700;
                font-size: 18px;*/
  width: 100%;
  text-align: right;
  color: #000000;
  font-size: 14px;
  /* Approximation due to font substitution */
  font-weight: 600;
  line-height: 32px;
  /* Approximation due to font substitution */
  text-align: right;
}

.content-review-widget .review-item .review-rating {
  display: block;
}

.content-review-widget .review-item .review-rating ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.content-review-widget .review-item .review-rating ul li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.content-review-widget .review-item .review-rating ul li i {
  color: #c5c5c4;
  padding: 2px;
  font-size: 18px;
}

.content-review-widget .review-item .review-rating ul li i.star-full {
  color: #e6762c;
  /*background: linear-gradient(-60deg, #f6e6b4 0%, #ed9017 100%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;*/
}

.content-review-widget .review-item .review-rating ul li i.last-full {
  padding-right: 0;
}

.content-review-widget .review-item .review-rating ul li i.first-empty {
  padding-left: 0;
}

.content-review-widget .review-item .review-content {
  padding: 0;
}

.content-review-widget .review-item .review-text {
  font-family: "Roboto", sans-serif;
  color: #232323;
  font-size: 16px;
  /* Approximation due to font substitution */
  font-weight: 300;
  line-height: 24px;
  /* Approximation due to font substitution */
  text-align: left;
}

#dealer-reviews .more-button-container {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

#dealer-reviews .more-button-container a {
  margin: 0 auto;
  color: #fff;
  background-color: #31728e;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 10px;
  border: 2px solid #31728e;
  text-transform: uppercase;
  display: block;
  width: 300px;
  cursor: pointer;
}

#dealer-reviews .reviews-container.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

#dealer-reviews .reviews-container .review-item {
  padding: 10px 15px;
}

#dealer-reviews .reviews-container .review-item .review-quote img {
  width: 100%;
  max-width: 30px;
  min-width: 30px;
}

@media (max-width: 991px) {
  #dealer-reviews .reviews-container .review-item .review-quote {
    margin-bottom: 7px;
  }
}

#dealer-reviews .reviews-container .review-item .review-author {
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  /*font-weight: 700;
                font-size: 18px;*/
  width: 100%;
  text-align: right;
  color: #000000;
  font-size: 14px;
  /* Approximation due to font substitution */
  font-weight: 600;
  line-height: 32px;
  /* Approximation due to font substitution */
  text-align: right;
}

#dealer-reviews .reviews-container .review-item .review-rating {
  display: block;
}

#dealer-reviews .reviews-container .review-item .review-rating ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#dealer-reviews .reviews-container .review-item .review-rating ul li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

#dealer-reviews .reviews-container .review-item .review-rating ul li i {
  color: #c5c5c4;
  padding: 2px;
  font-size: 18px;
}

#dealer-reviews .reviews-container .review-item .review-rating ul li i.star-full {
  color: #e6762c;
  /*background: linear-gradient(-60deg, #f6e6b4 0%, #ed9017 100%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;*/
}

#dealer-reviews .reviews-container .review-item .review-rating ul li i.last-full {
  padding-right: 0;
}

#dealer-reviews .reviews-container .review-item .review-rating ul li i.first-empty {
  padding-left: 0;
}

#dealer-reviews .reviews-container .review-item .review-text {
  ont-family: "Roboto", sans-serif;
  color: #232323;
  font-size: 16px;
  /* Approximation due to font substitution */
  font-weight: 300;
  line-height: 24px;
  /* Approximation due to font substitution */
  text-align: left;
}

.locationhiddenfield label {
  display: none;
}

.subarulocations select {
  width: 100%;
}

.form-thank-you .thank-you-locations {
  text-align: center;
  margin-top: 50px;
}

.form-thank-you .thank-you-locations .location-item {
  width: 100%;
  max-width: 300px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  position: relative;
  margin: 0 15px 30px;
  display: inline-block;
  text-align: left;
}

.form-thank-you .thank-you-locations .location-item .heading {
  font-size: 18px;
  font-weight: bold;
}

.form-thank-you .thank-you-locations .location-item .info {
  font-size: 14px;
}

.form-thank-you .thank-you-locations .location-item .info .address-item {
  display: block;
}

.form-thank-you .thank-you-locations .location-item .info .address-item span {
  font-weight: bold;
}

@media (max-width: 365px) {
  .form-thank-you .thank-you-locations .location-item {
    max-width: unset !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.request-info-forms-container .container:first-child {
  max-width: 1000px;
  margin: 0 auto;
  background-color: #f1f1f1;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.request-info-forms-container .umbraco-forms-form {
  margin-bottom: 0 !important;
}

.request-info-forms-container .vehicle-request-container {
  text-align: center;
}

.request-info-forms-container .vehicle-request-container::after {
  display: block;
  content: "";
  clear: both;
}

.request-info-forms-container .vehicle-request-container .no-vehicle-message {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #1d4566;
  font-weight: 700;
  max-width: 60%;
  margin: 0 auto;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container {
  margin-bottom: 30px;
  /*width: 400px;
            height: 103px;*/
}

@media (max-width: 767px) {
  .request-info-forms-container .vehicle-request-container .vehicle-request-item-container {
    margin-bottom: 0;
  }
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .col-sm-10 {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container:nth-child(odd) {
  padding-left: 0;
  padding-right: 30px;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container:nth-child(even) {
  padding-left: 30px;
  padding-right: 0;
}

@media (max-width: 991px) {
  .request-info-forms-container .vehicle-request-container .vehicle-request-item-container:nth-child(odd) {
    padding-right: 0;
  }
  .request-info-forms-container .vehicle-request-container .vehicle-request-item-container:nth-child(even) {
    padding-left: 0;
  }
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item {
  background-color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 15px;
  position: relative;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item::after {
  display: block;
  content: "";
  clear: both;
}

@media (max-width: 991px) {
  .request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item {
    width: 100%;
  }
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #595959;
  font-weight: 100;
  cursor: pointer;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .close-btn:hover, .request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .close-btn:active, .request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .close-btn:visited {
  text-decoration: none;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .image {
  float: left;
  width: 29.99999%;
  position: relative;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .image .photo-overlay {
  position: absolute;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .image img {
  max-width: 105px;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .info {
  float: left;
  width: 69.99999%;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .info .vehicle-title {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  line-height: 18px;
  /* Approximation due to font substitution */
  text-align: left;
  max-width: 95%;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .info .vehicle-price {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #000000;
  font-weight: 400;
  line-height: 36px;
  /* Approximation due to font substitution */
  text-align: left;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .info .vehicle-price .price {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #1d4566;
  font-weight: 700;
  line-height: 28px;
  /* Approximation due to font substitution */
  text-align: left;
}

@media (max-width: 991px) {
  .request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item {
    margin-bottom: 15px;
  }
}

@media (max-width: 500px) {
  .request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .image {
    margin-right: 15px;
    width: 20.99999%;
  }
  .request-info-forms-container .vehicle-request-container .vehicle-request-item-container .vehicle-request-item .image img {
    width: 100%;
  }
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container.col-md-12 {
  margin-right: 0;
  padding-right: 0;
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container.col-md-12 .vehicle-request-item {
  width: 49.99999%;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .request-info-forms-container .vehicle-request-container .vehicle-request-item-container.col-md-12 .vehicle-request-item {
    width: 100% !important;
  }
}

.request-info-forms-container .vehicle-request-container .vehicle-request-item-container.col-md-12:last-child {
  margin-bottom: 0;
}

.request-info-vehicle, .request-info-novehicle {
  font-size: 18px !important;
}

.request-info-form .umbraco-forms-navigation::after {
  display: block;
  content: "";
  clear: both;
}

.request-info-form .umbraco-forms-navigation .col-sm-10 {
  width: 100% !important;
  text-align: center !important;
  margin-left: 0 !important;
  padding-top: 15px;
}

.request-info-form .umbraco-forms-navigation .col-sm-10 input[type=submit] {
  background-color: #ce301a;
  border: none;
}

.request-info-form .umbraco-forms-navigation .col-sm-10 input[type=submit]:hover {
  background-color: #a12514;
}

.request-info-form fieldset {
  margin-bottom: 5px;
}

.request-info-form fieldset .row-fluid::after {
  display: block;
  content: "";
  clear: both;
}

.request-info-form fieldset .row-fluid .umbraco-forms-container .col-md-6, .request-info-form fieldset .row-fluid .umbraco-forms-container .col-md-12 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.request-info-form fieldset .form-group label {
  float: none;
  width: 100%;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #545454;
  font-weight: 400;
}

.request-info-form fieldset .form-group label .umbracoForms-Indicator, .request-info-form fieldset .form-group label .field-validation-error {
  color: #e24725;
}

.request-info-form fieldset .form-group .umbraco-forms-field-wrapper {
  width: 100% !important;
}

.request-info-form fieldset .form-group .umbraco-forms-field-wrapper.col-md-6:nth-child(odd) {
  padding-right: 7px !important;
}

.request-info-form fieldset .form-group .umbraco-forms-field-wrapper.col-md-6:nth-child(even) {
  padding-left: 7px !important;
}

.request-info-form fieldset .form-group input[type=text], .request-info-form fieldset .form-group textarea, .request-info-form fieldset .form-group select {
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #cccccc;
  box-sizing: border-box;
  background-color: #ffffff;
  /** FONT **/
  color: #141719;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
}

.request-info-form fieldset .form-group input[type=text]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #141719;
}

.request-info-form fieldset .form-group input[type=text]::-moz-placeholder {
  /* Firefox 19+ */
  color: #141719;
}

.request-info-form fieldset .form-group input[type=text]:-ms-input-placeholder {
  /* IE 10+ */
  color: #141719;
}

.request-info-form fieldset .form-group input[type=text]:-moz-placeholder {
  /* Firefox 18- */
  color: #141719;
}

.request-info-form fieldset .form-group input[type=text], .request-info-form fieldset .form-group select {
  height: 45px;
}

.request-info-form fieldset .form-group select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 4px 12px;
}

.request-info-form fieldset .form-group select::-ms-expand {
  display: none;
}

.request-info-form fieldset .form-group textarea {
  height: 114px;
}

.request-info-form fieldset .form-group.dealerlocationsdropdownpicker {
  width: 100% !important;
}

.request-info-form fieldset .form-group.dealerlocationsdropdownpicker select {
  width: 100%;
}

.request-info-form .chooseyourtime, .request-info-form .dealerlocationsdropdownpicker, .request-info-form .subarulocations {
  position: relative;
}

.request-info-form .chooseyourtime label:after, .request-info-form .dealerlocationsdropdownpicker label:after, .request-info-form .subarulocations label:after {
  content: "\f078";
  font-family: "FontAwesome";
  font-size: 11px;
  color: #000;
  right: 35px;
  top: 41px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  z-index: 1;
}

.umbraco-forms-field.dropdown label:after {
  content: "\f078";
  font-family: "FontAwesome";
  font-size: 11px;
  color: #000;
  right: 35px;
  top: 41px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  z-index: 1;
}

.umb-grid .figure {
  /*display: table;
        text-align: center;*/
}

.umb-grid .figure .caption {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
}

.umb-grid .figure.left {
  max-width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 20px;
  height: auto !important;
}

@media (min-width: 500px) {
  .umb-grid .figure.left {
    float: left;
    margin-left: 0 !important;
    margin-right: 20px !important;
    max-width: 500px;
  }
}

.umb-grid .figure.center {
  max-width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 20px;
}

@media (min-width: 500px) {
  .umb-grid .figure.center {
    max-width: 500px;
  }
}

.umb-grid .figure.right {
  max-width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 20px;
  height: auto !important;
}

@media (min-width: 500px) {
  .umb-grid .figure.right {
    float: right;
    margin-right: 0 !important;
    margin-left: 20px !important;
    max-width: 500px;
  }
}

.umb-grid .figure.right img {
  width: 100%;
}

#site-popup {
  display: none;
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1010;
}

#site-popup .p-i {
  display: none;
}

#site-popup .mobile {
  display: none;
}

#site-popup.active {
  display: flex;
}

#site-popup .overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#site-popup .inner-popup {
  /*position: fixed;
        top: 50%;
        left: 50%;
        display: inline-block;
        transform: translateY(-50%) translateX(-50%);*/
  display: block;
  z-index: 2;
  max-height: 100vh;
  overflow-y: auto;
  position: relative;
}

#site-popup .inner-popup img {
  max-width: 800px;
}

#site-popup .inner-popup .btn-close {
  display: block;
  font-size: 34px;
  padding: 5px;
  position: absolute;
  top: 0px;
  right: 15px;
  z-index: 2;
}

#site-popup .inner-popup .btn-close:hover {
  cursor: pointer;
  color: black;
}

#site-popup .inner-popup .inner-content {
  background-color: white;
  padding: 30px;
  position: relative;
}

#site-popup .inner-popup .inner-content .searched-for {
  margin-bottom: 2em;
}

#site-popup .inner-popup .inner-content .title {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}

#site-popup .inner-popup .inner-content .subtitle {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
}

#site-popup .inner-popup .inner-content a {
  border-bottom: 1px dotted #337ab7;
}

#site-popup .inner-popup .inner-content a:hover {
  text-decoration: none;
  border-bottom: 1px solid #22527b;
}

#site-popup .inner-popup .inner-content .items {
  display: flex;
  margin-top: 30px;
  margin-bottom: 0;
  flex-wrap: wrap;
  justify-content: center;
}

#site-popup .inner-popup .inner-content .items .form-horizontal {
  width: 100%;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group .label {
  /*display: block;
                            width: 100%;*/
  font-size: 14px;
  color: #000;
  text-align: left;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group .label.error, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group .label span {
  color: #a11c1c;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group input, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group select, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea {
  color: #000;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group input.error, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group select.error, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea.error {
  border-color: #a11c1c;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group input ::-webkit-input-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group select ::-webkit-input-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000 !important;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group input :-moz-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group select :-moz-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000 !important;
  opacity: 1;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group input ::-moz-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group select ::-moz-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000 !important;
  opacity: 1;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group input :-ms-input-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group select :-ms-input-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group input ::-ms-input-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group select ::-ms-input-placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000 !important;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group input ::placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group select ::placeholder, #site-popup .inner-popup .inner-content .items .form-horizontal .form-group textarea ::placeholder {
  /* Most modern browsers support this now. */
  color: #000 !important;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons {
  margin-bottom: 0;
  padding-top: 15px;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons a.orange-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 10px 55px;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
  font-weight: 600;
  white-space: normal;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons a.orange-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons a.orange-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#site-popup .inner-popup .inner-content .items .form-horizontal .form-group.buttons a.orange-btn:hover {
  background-color: #a5341c;
}

#site-popup .inner-popup .inner-content.selected .buttons a.orange-btn {
  padding: 10px 55px;
  background-color: #d04224;
  color: #fff;
  text-shadow: 0px 0px 5px #792615;
}

#site-popup .inner-popup .inner-content.selected .buttons a.orange-btn span:after {
  font-family: FontAwesome;
  content: "\f078";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#site-popup .inner-popup .inner-content.selected .buttons a.orange-btn span.open:after {
  font-family: FontAwesome;
  content: "\f077";
  color: #fff;
  margin-left: 5px;
  font-weight: 100;
}

#site-popup .inner-popup .inner-content.selected .buttons a.orange-btn:hover {
  background-color: #a5341c;
}

@media (max-width: 1199px) {
  #site-popup .inner-popup .inner-content .items .item img {
    width: 160px;
  }
}

@media (max-width: 991px) {
  #site-popup .inner-popup .inner-content .items .item {
    flex: 0 1 auto;
  }
  #site-popup .inner-popup .inner-content .items .item label {
    padding: 0;
  }
  #site-popup .inner-popup .inner-content .items .item img {
    width: 120px;
  }
}

@media (max-width: 991px) {
  #site-popup .desktop {
    width: 100%;
  }
}

@media (max-width: 767px) {
  #site-popup .mobile {
    display: block;
    width: 100%;
  }
  #site-popup .desktop {
    display: none;
  }
  #site-popup .inner-popup {
    overflow: hidden;
  }
  #site-popup .inner-popup .inner-content {
    padding: 15px;
  }
  #site-popup .inner-popup .inner-content .searched-for {
    margin-bottom: 10px;
    margin-right: 30px;
    margin-left: 30px;
  }
  #site-popup .inner-popup .inner-content .title {
    font-size: 20px;
    line-height: 1.25;
  }
  #site-popup .inner-popup .inner-content .title .br-mobile {
    display: block;
  }
  #site-popup .inner-popup .inner-content .subtitle {
    font-size: 15px;
  }
  #site-popup .inner-popup .inner-content .items {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  #site-popup .inner-popup .inner-content .items .item {
    flex: 0 1 50%;
  }
  #site-popup .inner-popup .inner-content .items .item label {
    font-size: 15px;
  }
  #site-popup .inner-popup .inner-content .items .item img {
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  #site-popup .inner-popup .inner-content .buttons a.orange-btn {
    padding: 10px 20px;
    margin-left: 0;
    display: block;
  }
  #site-popup .inner-popup .inner-content .everything {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #site-popup .inner-popup .inner-content .everything a {
    font-size: 16px;
  }
}
